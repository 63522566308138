import { Adsense } from '../../../components/Adsense'
import { Calendar } from '../../../components/Calendar'
import { ExpensesByPeriodChart } from '../../../components/Charts/ExpensesByPeriod'
import { usePlan } from '../../../hooks/PlanContextProvider'
import { Container } from './styles'

export const LateralPanel: React.FC = () => {
  const { useAd } = usePlan()

  return (
    <Container>
      <div className="calendar-row">
        <Calendar />
        {useAd && (
          <Adsense
            adSlot="5383251902"
            format="fixed"
            size={{ x: '12.rem', y: '36.8rem' }}
          />
        )}
      </div>

      <div className="chart">
        <ExpensesByPeriodChart />
      </div>
    </Container>
  )
}
