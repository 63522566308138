import { FormProvider, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { AxiosError } from 'axios'

import { Button } from '../../../components/Button'
import { Input } from '../../../components/Inputs/Input'
import { useAuth } from '../../../hooks/AuthContextProvider'
import { useToast } from '../../../hooks/ToastContextProvider'
import { api } from '../../../services/api'
import { Container, Title } from './styles'

interface IFormData {
  anme: string
  email: string
  password: string
  passwordConfirmation: string
}

export const SignUp: React.FC = () => {
  const methods = useForm<IFormData>()
  const { doLogin } = useAuth()
  const { addToast } = useToast()

  async function handleSubmit(data: IFormData) {
    try {
      await api.post('/auth/signup', data)
      addToast({
        title: 'Sucesso!',
        description: 'A sua conta foi criada com sucesso!',
        type: 'success',
        time: 5,
      })
      await doLogin({ email: data.email, password: data.password })
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response?.status === 403) {
          addToast({
            title: 'Oops!',
            description: 'Parece que esse e-mail já está sendo usado...',
            type: 'error',
            time: 5,
          })
          return
        }
      }
      addToast({
        title: 'Oops!',
        description: 'Houve algum problema ao criar a sua conta...',
        type: 'error',
        time: 5,
      })
    }
  }

  return (
    <Container>
      <Title>Criar Conta</Title>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <Input
            name="name"
            label="Seu nome"
            placeholder="Digite o seu lindo nome"
          />
          <Input
            name="email"
            label="E-mail"
            placeholder="Digite o seu e-mail"
          />
          <Input
            name="password"
            label="Senha"
            type="password"
            placeholder="Digite a sua senha"
          />
          <Input
            name="passwordConfirmation"
            label="Confirmar Senha"
            type="password"
            placeholder="Digite a sua senha novamente"
          />
          <Button type="submit">Criar Conta</Button>
        </form>
        <p className="warn">
          Ao criar uma conta, você concorda
          <br /> com os nossos <Link to="/docs/use-terms">Termos de Uso</Link>
          <br /> e <Link to="/docs/privacy-terms">Política de Privacidade</Link>
        </p>
        <Link to="/auth/login">
          Já tem uma conta? <u>Entrar</u>
        </Link>
      </FormProvider>
    </Container>
  )
}
