import { useEffect, useState } from 'react'

import { type ICategory } from '../../../@types/ICategory'
import { Button } from '../../../components/Button'
import { AddCategoryModal } from '../../../components/Modals/AddCategoryModal'
import { YesNoModal } from '../../../components/Modals/YesNoModal'
import { useChanges } from '../../../hooks/ChangesContextProvider'
import { useModal } from '../../../hooks/ModalContextProvider'
import { useWallet } from '../../../hooks/WalletContextProvider'
import { api } from '../../../services/api'
import { Category } from './Category'
import { Container, Header } from './styles'

export const MainPanel: React.FC = () => {
  const [categories, setCategories] = useState<ICategory[]>([])
  const { changes, handleChange } = useChanges()
  const { addModal, removeModal } = useModal()
  const { currentWallet } = useWallet()

  const handleOpenAddCategoryModal = () => {
    addModal({
      id: 'modal-add-category',
      content: (
        <AddCategoryModal
          onClose={() => {
            removeModal('modal-add-category')
          }}
        />
      ),
    })
  }

  const handleEdit = (id: string) => {
    addModal({
      id: 'modal-edit-category',
      content: (
        <AddCategoryModal
          id={id}
          onClose={() => {
            removeModal('modal-edit-category')
          }}
        />
      ),
    })
  }

  const handleDelete = async (id: string) => {
    const category = categories.find((c) => c.id === id)
    if (!category) {
      console.error('Category not found')
      return
    }

    addModal({
      id: 'modal-delete-category',
      content: (
        <YesNoModal
          title="Deletar Categoria"
          description={`Tem certeza que deseja deletar a categoria ${category.title}?`}
          onYes={async () => {
            await api.delete(`/categories/${id}`)
            removeModal('modal-delete-category')
            handleChange('categories')
          }}
          onClose={() => {
            removeModal('modal-delete-category')
          }}
        />
      ),
    })
  }

  useEffect(() => {
    async function getCategories() {
      const response = await api.get<ICategory[]>('/categories', {
        params: {
          wallet_id: currentWallet?.id ?? undefined,
        },
      })
      setCategories(response.data)
    }

    getCategories().catch(console.error)
  }, [changes.categories, currentWallet?.id])

  return (
    <Container>
      <Header>
        <h3>Categorias</h3>

        <Button model="small" onClick={handleOpenAddCategoryModal}>
          Adicionar Categoria
        </Button>
      </Header>

      <div className="category-list">
        {categories.map((category) => (
          <Category
            key={category.id}
            data={category}
            onEdit={() => {
              handleEdit(category.id)
            }}
            onDelete={() => {
              handleDelete(category.id).catch(console.error)
            }}
          />
        ))}

        {categories.length === 0 && (
          <span className="empty">
            Nenhuma categoria cadastrada, clique em &quot;Adicionar
            Categoria&quot; para começar a adicionar.
          </span>
        )}
      </div>
    </Container>
  )
}
