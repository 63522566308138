import * as Yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'

export interface IFormData {
  title: string
  color: string
  iconUrl: string
  description?: string
  category?: string
  type?: string
}

export const formSchema = yupResolver(
  Yup.object().shape({
    title: Yup.string().required('Informe o título da categoria'),
    color: Yup.string().required('Informe a cor da categoria'),
    iconUrl: Yup.string().required('Informe a URL do ícone da categoria'),
    description: Yup.string().optional(),
    category: Yup.string().optional(),
    type: Yup.string().optional(),
  }),
)
