import { X } from '@phosphor-icons/react'

import { type IToast } from '../../../@types/IToast'
import { Container, Toast, TimeBar } from './styles'

interface IToastListProps {
  toasts: IToast[]
}

export const ToastList: React.FC<IToastListProps> = ({ toasts }) => {
  return (
    <Container>
      {toasts.map((toast) => (
        <Toast key={toast.id} toastType={toast.type} time={toast.time ?? 6}>
          <button>
            <X size={'2.4rem'} />
          </button>
          <h4>{toast.title}</h4>
          <p>{toast.description}</p>
          <section className="bar">
            <TimeBar className="time" time={toast.time ?? 6} />
          </section>
        </Toast>
      ))}
    </Container>
  )
}
