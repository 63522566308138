import styled from 'styled-components'

export const Container = styled.section`
  grid-area: transactions;
  padding: 1.6rem 0.8rem 1.6rem 1.6rem;
  background-color: ${(props) => props.theme.colors.backgroundBlack};
  border-radius: 1.6rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-height: 70rem;

  @media (max-width: 1000px) {
    min-height: 70vh;
  }
`

export const Header = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem 2.4rem 1.6rem 1.6rem;

  @media (max-width: 460px) {
    flex-direction: column;
    gap: 0.8rem;
    text-align: center;
  }
`

export const TransactionList = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  overflow: auto;
  padding-right: 0.8rem;

  .empty {
    flex: 1;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    opacity: 0.6;
    padding: 3.2rem 0;
  }
`
