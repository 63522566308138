import styled from 'styled-components'

export const Container = styled.article`
  flex: 1;
  padding: 1.6rem 3.2rem 0.8rem;
  display: grid;
  grid-template-columns: 1fr 50rem;
  grid-template-rows: max-content 1fr;
  grid-template-areas: 'overview lateral' 'transactions lateral';
  overflow: auto;
  gap: 3.2rem;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr 40rem;
  }

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-template-areas: 'overview' 'lateral' 'transactions';
    grid-template-rows: max-content max-content max-content;
  }

  @media (max-width: 460px) {
    padding: 1.6rem 1.6rem;
  }
`
