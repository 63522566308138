import { LateralPanel } from './LateralPanel'
import { OverviewPanel } from './OverviewPanel'
import { RecentTransactionsPanel } from './RecentTransactionsPanel'
import { Container } from './styles'

export const Home: React.FC = () => {
  return (
    <Container>
      <OverviewPanel />
      <LateralPanel />
      <RecentTransactionsPanel />
    </Container>
  )
}
