import { Controller, useFormContext } from 'react-hook-form'

import { Container } from './styles'

interface IOwnProps {
  label: string
  name: string
  align?: 'left' | 'center' | 'right'
  formatter?: (value: string) => string
}

type IInputWithFormaterProps = IOwnProps & React.HTMLProps<HTMLInputElement>

export const InputWithFormater: React.FC<IInputWithFormaterProps> = ({
  label,
  required,
  name,
  formatter,
  align = 'left',
  style,
  onChange: handleChange,
  ...props
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  const error = errors[name]?.message?.toString() ?? ''

  return (
    <Container>
      <p>
        <strong>
          {label} {required && '*'}
        </strong>
      </p>
      <Controller
        control={control}
        name={name}
        defaultValue=""
        render={({ field: { onChange, ...field } }) => (
          <input
            type="text"
            style={{ ...style, textAlign: align }}
            {...field}
            {...props}
            onChange={(e) => {
              if (formatter) {
                onChange(formatter(e.target.value))
              } else {
                onChange(e.target.value)
              }
              if (handleChange) {
                handleChange(e)
              }
            }}
          />
        )}
      ></Controller>
      {error && <span className="error">{error}</span>}
    </Container>
  )
}
