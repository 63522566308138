import { useFormContext } from 'react-hook-form'

import { Container } from './styles'

interface IOwnProps {
  label: string
  name: string
}

type IInputCheckboxProps = IOwnProps & React.HTMLProps<HTMLInputElement>

export const InputCheckbox: React.FC<IInputCheckboxProps> = ({
  label,
  required,
  name,
  ...props
}) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext()
  const error = errors[name]?.message?.toString() ?? ''
  const value = watch(name)

  return (
    <Container>
      <div className="input-row">
        <p>
          <strong>
            {label} {required && '*'}
          </strong>
        </p>
        <input required={required} {...register(name)} {...props} />
        <div className={`toggle${value ? ' active' : ''}`}></div>
      </div>
      {error && <span className="error">{error}</span>}
    </Container>
  )
}
