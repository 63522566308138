import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.4rem;
    margin-bottom: 2.4rem;
  }

  a {
    margin-top: 0.8rem;
  }

  .warn {
    text-align: center;
    a {
      color: ${({ theme }) => theme.colors.primary};
      text-decoration: underline;
    }
  }
`

export const Title = styled.h2`
  text-align: center;
  margin-bottom: 3.2rem;
`
