import styled from 'styled-components'

export const Container = styled.article`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 1.6rem 3.2rem 0.8rem;

  @media (max-width: 460px) {
    padding: 1.6rem 1.6rem 0.8rem;
  }
`
