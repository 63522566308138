import { createContext, useState, useEffect, useContext } from 'react'

import { jwtDecode } from 'jwt-decode'

import { type IWallet } from '../../@types/IWallet'
import { api } from '../../services/api'
import { useAuth, type IAuthUserPayload } from '../AuthContextProvider'
import { useChanges } from '../ChangesContextProvider'

interface IWalletContextProvider {
  children: React.ReactNode
}

interface ICreateContext {
  currentWallet: IWallet | null
  wallets: IWallet[]
  changeWallet: (id: string) => void
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const WalletContext = createContext({} as ICreateContext)

function getWalletsFromLocalStorage() {
  const token = localStorage.getItem('@okaneko:token')
  if (token) {
    const decoded = jwtDecode<IAuthUserPayload>(token)
    if (!decoded?.wallets) return []
    return decoded.wallets as unknown as IWallet[]
  }
  return []
}

export const WalletContextProvider: React.FC<IWalletContextProvider> = ({
  children,
}) => {
  const [wallets, setWallets] = useState<IWallet[]>(getWalletsFromLocalStorage)
  const [currentWallet, setCurrentWallet] = useState<IWallet | null>(
    wallets[0] || null,
  )
  const { token } = useAuth()
  const { changes } = useChanges()

  function changeWallet(id: string) {
    const wallet = wallets.find((wallet) => wallet.id === id)
    if (!wallet) return

    setCurrentWallet(wallet)
  }

  useEffect(() => {
    if (!token) return
    if (wallets.length > 0) {
      if (!currentWallet) {
        setCurrentWallet(wallets[0])
      } else if (!wallets.some((wallet) => wallet.id === currentWallet.id)) {
        setCurrentWallet(wallets[0])
      }
    }
  }, [currentWallet, wallets, token])

  useEffect(() => {
    async function getWallets() {
      if (!token) return
      const response = await api.get<IWallet[]>('/wallets')
      setWallets(response.data)
    }
    getWallets().catch(console.error)
  }, [changes.wallets, token])

  return (
    <WalletContext.Provider value={{ wallets, currentWallet, changeWallet }}>
      {children}
    </WalletContext.Provider>
  )
}

export const useWallet = () => {
  const context = useContext(WalletContext)

  if (!context) {
    throw new Error('Theres a problem with WalletContext')
  }

  return context
}
