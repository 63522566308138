import { createContext, useState, useContext } from 'react'

import { type IChanges } from '../../@types/IChanges'

interface IChangesContextProvider {
  children: React.ReactNode
}

interface ICreateContext {
  changes: IChanges
  handleChange: (changeType: keyof IChanges) => void
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const ChangesContext = createContext({} as ICreateContext)

export const ChangesContextProvider: React.FC<IChangesContextProvider> = ({
  children,
}) => {
  const [changes, setChanges] = useState<IChanges>({
    categories: 0,
    profile: 0,
    transactions: 0,
    wallets: 0,
  })

  function handleChange(changeType: keyof IChanges) {
    setChanges((state) => {
      const size = state[changeType] + 1

      return { ...state, [changeType]: size }
    })
  }

  return (
    <ChangesContext.Provider value={{ changes, handleChange }}>
      {children}
    </ChangesContext.Provider>
  )
}

export const useChanges = () => {
  const context = useContext(ChangesContext)

  if (!context) {
    throw new Error('Theres a problem with ChangesContext')
  }

  return context
}
