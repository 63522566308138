import { useState } from 'react'

import { CaretDown } from '@phosphor-icons/react'

import { useWallet } from '../../../hooks/WalletContextProvider'
import { Container, WalletList, WalletItem } from './styles'

export const ChangeWallet: React.FC = () => {
  const [isListActive, setIsListActive] = useState(false)
  const { wallets, currentWallet, changeWallet } = useWallet()

  return (
    <Container>
      <button
        type="button"
        onFocus={() => {
          setIsListActive(true)
        }}
        onBlur={() => {
          setIsListActive(false)
        }}
      >
        {currentWallet?.name}
        <CaretDown size={'2.4rem'} />
      </button>
      <WalletList className={isListActive ? 'active' : ''}>
        {wallets.map((wallet) => (
          <WalletItem key={wallet.id}>
            <button
              type="button"
              onMouseDown={() => {
                changeWallet(wallet.id)
              }}
            >
              {wallet.name}
            </button>
          </WalletItem>
        ))}
      </WalletList>
    </Container>
  )
}
