import { Navigate, Outlet } from 'react-router-dom'

import { Adsense } from '../../../components/Adsense'
import { useAuth } from '../../../hooks/AuthContextProvider'
import { usePlan } from '../../../hooks/PlanContextProvider'
import { Header } from './Header'
import { Menu } from './Menu'
import { Container, Content, Footer } from './styles'

export interface IAuthLayout {
  children?: React.ReactNode
}

export const AppLayout: React.FC<IAuthLayout> = ({ children }) => {
  const { isLogged } = useAuth()
  const { useAd } = usePlan()

  if (!isLogged) {
    return <Navigate to="/auth/login" />
  }

  return (
    <Container useAd={useAd}>
      <Menu />
      <Header />

      <Content>
        <Outlet />
        {children}
      </Content>

      {useAd && (
        <Footer>
          <Adsense
            adSlot="4237970438"
            format="fixed"
            size={{ y: '8rem', x: '72rem' }}
          />
          <Adsense
            adSlot="4237970438"
            format="fixed"
            size={{ y: '8rem', x: '72rem' }}
          />
        </Footer>
      )}
    </Container>
  )
}
