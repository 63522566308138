import { Container } from './styles'

export const Error404: React.FC = () => {
  return (
    <Container>
      <h1>
        Erro <br /> <span>404</span>
      </h1>
      <h2>Página não encontrada.</h2>
    </Container>
  )
}
