import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 6.4rem;

  h1 {
    text-align: center;
  }

  ul {
    padding-left: 3.2rem;
  }

  main {
    max-width: 80rem;
    color: ${({ theme }) => theme.colors.backgroundLighter};
  }
  a {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
  }

  header,
  footer {
    text-align: center;
    margin: 3.2rem;

    img {
      width: 30rem;
    }
  }

  footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
  }
`
