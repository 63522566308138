import { useEffect, useRef, useState } from 'react'

import { v4 as uuid } from 'uuid'

import { Plus, Prohibit } from '@phosphor-icons/react'

import { type ICategory } from '../../../@types/ICategory'
import { type Optional } from '../../../@types/Optional'
import { useChanges } from '../../../hooks/ChangesContextProvider'
import { useModal } from '../../../hooks/ModalContextProvider'
import { api } from '../../../services/api'
import { getRecommendedCategories } from '../../../services/utils/getRecommendedCategories'
import { CategoryIcon } from '../../CategoryIcon'
import { AddCategoryModal } from '../AddCategoryModal'
import {
  AddCategoryButton,
  CategoryItemButton,
  CategoryList,
  Container,
  NoneCategoryButton,
} from './styles'

interface IListCategoriesModalProps {
  preloadedCategories: ICategory[]
  onSelectCategory: (category: Optional<ICategory, 'id'> | null) => void
  showRecommendations?: boolean
  noSubcategory?: boolean
  type?: string | null
}

export const ListCategoriesModal: React.FC<IListCategoriesModalProps> = ({
  preloadedCategories,
  onSelectCategory,
  showRecommendations,
  noSubcategory,
  type,
}) => {
  const uniqueId = useRef<string | null>(null)
  const [userCategories, setUserCategories] = useState([...preloadedCategories])
  const recommendedCategories = getRecommendedCategories(userCategories)
  const { addModal, removeModal } = useModal()
  const { changes } = useChanges()

  const getUserCategories = async () => {
    const { data } = await api.get<ICategory[]>('/categories')

    setUserCategories(data)
  }

  const handleCloseModal = () => {
    getUserCategories().catch(console.error)

    removeModal(`list-categories-add-category-modal-${uniqueId.current ?? ''}`)
  }

  const handleOpenAddCategoryModal = () => {
    addModal({
      id: `list-categories-add-category-modal-${uniqueId.current ?? ''}`,
      content: (
        <AddCategoryModal
          onClose={handleCloseModal}
          noSubcategory={noSubcategory}
        />
      ),
    })
  }

  useEffect(() => {
    getUserCategories().catch(console.error)
  }, [changes.categories])

  useEffect(() => {
    if (!uniqueId.current) {
      uniqueId.current = uuid()
    }
  }, [])

  return (
    <Container>
      <h3>Escolha uma Categoria</h3>
      {showRecommendations && <h4>Categorias do usuário</h4>}
      <CategoryList>
        {userCategories
          .filter((category) => !noSubcategory || !category.category)
          .map((category) => (
            <CategoryItemButton
              key={category.id}
              type="button"
              title={category.title}
              onClick={() => {
                onSelectCategory(category)
              }}
            >
              <CategoryIcon category={category} />
              <p>{category.title}</p>
            </CategoryItemButton>
          ))}
        <NoneCategoryButton
          type="button"
          onClick={() => {
            onSelectCategory(null)
          }}
        >
          <Prohibit size={'3.2rem'} />
          <p>Nenhuma categoria</p>
        </NoneCategoryButton>
        <AddCategoryButton type="button" onClick={handleOpenAddCategoryModal}>
          <Plus size={'3.2rem'} />
          <p>Adicionar categoria</p>
        </AddCategoryButton>
      </CategoryList>
      {showRecommendations && (
        <>
          <h4>Categorias recomendadas</h4>
          <CategoryList>
            {recommendedCategories
              .filter((category) => !category.type || category.type === type)
              .map((category) => (
                <CategoryItemButton
                  key={category.id}
                  type="button"
                  title={category.title}
                  onClick={() => {
                    onSelectCategory({ ...category, id: undefined })
                  }}
                >
                  <CategoryIcon category={category} />
                  <p>{category.title}</p>
                </CategoryItemButton>
              ))}
          </CategoryList>
        </>
      )}
    </Container>
  )
}
