import { useState, useEffect, useRef } from 'react'
import { SketchPicker } from 'react-color'
import { useFormContext } from 'react-hook-form'

import { getRecommendedColors } from '../../../services/utils/getRecommendedColors'
import { Container, PreviewButton } from './styles'

interface IOwnProps {
  label: string
  required?: boolean
  name: string
}

type IInputColorProps = IOwnProps & React.HTMLProps<HTMLInputElement>

const recommendedColors = getRecommendedColors()

function hasParent(
  currentEl?: HTMLElement | null,
  containerEl?: HTMLDivElement | null,
) {
  if (!currentEl || !containerEl) return false

  if (currentEl === containerEl) {
    return true
  }
  return hasParent(currentEl.parentElement, containerEl)
}

export const InputColor: React.FC<IInputColorProps> = ({
  label,
  required,
  name,
  ...props
}) => {
  const { register, setValue, watch } = useFormContext()
  const containerRef = useRef<HTMLDivElement>(null)
  const [active, setActive] = useState(false)
  const color = watch(name)

  useEffect(() => {
    function clickOut(e: MouseEvent) {
      const clikedOut = !hasParent(
        e.target as HTMLDivElement,
        containerRef.current,
      )

      if (clikedOut) {
        setActive(false)
      }
    }
    if (active) {
      window.addEventListener('click', clickOut)
    } else {
      window.removeEventListener('click', clickOut)
    }

    return () => {
      window.removeEventListener('click', clickOut)
    }
  }, [active])

  useEffect(() => {
    setValue(name, color)
  }, [color, name, setValue])

  return (
    <Container ref={containerRef}>
      <p>
        <strong>
          {label} {required && '*'}
        </strong>
      </p>
      <input type="hidden" required={required} {...props} {...register(name)} />
      <PreviewButton
        color={color}
        type="button"
        onClick={() => {
          setActive(true)
        }}
      />
      {active && (
        <SketchPicker
          color={color}
          onChange={(e) => {
            setValue(name, e.hex)
          }}
          className="color-picker"
          presetColors={recommendedColors}
        />
      )}
    </Container>
  )
}
