import { useState } from 'react'

import { CaretDown, SignOut } from '@phosphor-icons/react'

import { useAuth } from '../../../../../../hooks/AuthContextProvider'
import { Container, OptionList, OptionItem } from './styles'

export const User: React.FC = () => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false)
  const { user, doLogout } = useAuth()

  const initials = (user?.firstName?.[0] ?? '') + (user?.lastName?.[0] ?? '')

  return (
    <Container
      onFocus={() => {
        setIsOptionsOpen(true)
      }}
      onBlur={() => {
        setIsOptionsOpen(false)
      }}
    >
      <button type="button">
        <div className="user-photo">
          {user?.avatarUrl && <img src={user.avatarUrl} alt={user.name} />}
          {!user?.avatarUrl && <p>{initials}</p>}
        </div>

        <p>{user?.firstName}</p>

        <CaretDown size={'2.4rem'} />
      </button>

      <OptionList className={isOptionsOpen ? 'active' : ''}>
        <OptionItem>
          <button type="button" onClick={doLogout}>
            <SignOut size={'2.4rem'} />
            Sair
          </button>
        </OptionItem>
      </OptionList>
    </Container>
  )
}
