import styled from 'styled-components'

export const Container = styled.section`
  min-width: 5.6rem;
  min-height: 5.6rem;
  max-width: 5.6rem;
  max-height: 5.6rem;
  position: relative;

  > button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.colors.backgroundBlack};
    color: ${(props) => props.theme.colors.textLight};
    border: none;
    min-width: 5.6rem;
    min-height: 5.6rem;
    max-width: 5.6rem;
    max-height: 5.6rem;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.2);
    transition: 0.2s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
`

export const NotificationUl = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: calc(100% + 0.4rem);
  left: 0;
  background-color: ${(props) => props.theme.colors.backgroundDark};
  border-radius: 0.8rem;
  overflow: auto;
  max-height: 0;
  z-index: 999;
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.2);

  &.active {
    max-height: 30rem;
  }

  .empty {
    width: 30rem;
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 1.6rem;
  }
`

export const NotificationItem = styled.li`
  display: flex;
  width: 30rem;
  position: relative;

  .info-button {
    flex: 1;
    display: flex;
    padding: 0.8rem;
    background: none;
    border: none;
    cursor: pointer;
    transition: 0.2s;

    img {
      margin-right: 0.8rem;
      width: 3.2rem;
      height: 3.2rem;
      object-fit: cover;
      border-radius: 50%;
      border: 0.2rem solid ${(props) => props.theme.colors.backgroundDark};
    }

    p {
      display: flex;
      align-items: center;
      text-align: left;
      flex: 1;
      min-height: 3.2rem;
      padding-right: 4.8rem;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  .close-button {
    position: absolute;
    top: 0.8rem;
    right: 0.8rem;
    padding: 0.8rem;
    border-radius: 0.8rem;
    display: flex;
    justify-content: center;
    border: none;
    background: none;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
`
