import { Link } from 'react-router-dom'

import { Container } from './styles'

export const UseTerms: React.FC = () => {
  return (
    <Container>
      <h1>Contrato de Uso do Sistema Okaneko</h1>

      <p>
        1.1. Este contrato rege o uso do sistema de controle financeiro Okaneko,
        desenvolvido por FELIPE DS LIMA TECNOLOGIA EM INFORMÁTICA (CNPJ:
        32.864.763/0001-77) (&quot;Sistema&quot;).
      </p>
      <p>
        1.2. Ao utilizar o Okaneko, o usuário concorda com os termos e condições
        estabelecidos neste contrato.
      </p>

      <h2>2. Licença de Uso</h2>
      <p>
        2.1. FELIPE DS LIMA TECNOLOGIA EM INFORMÁTICA concede ao usuário uma
        licença não exclusiva, intransferível e limitada para usar o Okaneko,
        exclusivamente para fins de controle financeiro pessoal ou empresarial.
      </p>
      <p>
        2.2. O usuário não deve modificar, copiar, distribuir ou realizar
        engenharia reversa no Okaneko sem autorização prévia e por escrito da
        FELIPE DS LIMA TECNOLOGIA EM INFORMÁTICA.
      </p>

      <h2>3. Responsabilidade e Garantias</h2>
      <p>
        3.1. O Okaneko é fornecido &quot;como está&quot;, sem garantias de
        qualquer tipo, expressas ou implícitas, incluindo, mas não se limitando,
        a garantias de comercialização, adequação a um propósito específico e
        não violação.
      </p>
      <p>
        3.2. FELIPE DS LIMA TECNOLOGIA EM INFORMÁTICA não se responsabiliza por
        quaisquer danos diretos, indiretos, incidentais ou consequentes que
        possam resultar do uso ou incapacidade de uso do Okaneko.
      </p>
      <p>
        3.3. O Okaneko é um sistema de visualização e controle financeiro. Não
        há manipulação direta de dinheiro ou transações financeiras pelo
        Sistema.
      </p>

      <h2>4. Privacidade e Dados</h2>
      <p>
        4.1. Os dados inseridos no Okaneko serão armazenados e processados
        conforme as{' '}
        <Link to="/docs/privacy-terms"> políticas de privacidade</Link> da
        FELIPE DS LIMA TECNOLOGIA EM INFORMÁTICA.
      </p>
      <p>
        4.2. FELIPE DS LIMA TECNOLOGIA EM INFORMÁTICA compromete-se a não
        compartilhar os dados dos usuários com terceiros, exceto conforme
        necessário para cumprir a lei ou proteger os direitos da empresa.
      </p>

      <h2>5. Alterações no Contrato</h2>
      <p>
        5.1. FELIPE DS LIMA TECNOLOGIA EM INFORMÁTICA reserva-se o direito de
        alterar os termos deste contrato a qualquer momento. As alterações serão
        comunicadas ao usuário através do Sistema ou por outros meios
        apropriados.
      </p>
      <p>
        5.2. O uso continuado do Okaneko após as alterações constitui aceitação
        dos novos termos.
      </p>

      <h2>6. Cancelamento e Encerramento</h2>
      <p>
        6.1. O usuário pode encerrar o uso do Okaneko a qualquer momento,
        desinstalando o Sistema ou cessando o uso.
      </p>
      <p>
        6.2. FELIPE DS LIMA TECNOLOGIA EM INFORMÁTICA reserva-se o direito de
        cancelar o acesso do usuário ao Okaneko a qualquer momento, por qualquer
        motivo, sem aviso prévio.
      </p>

      <h2>7. Disposições Finais</h2>
      <p>
        7.1. Este contrato é regido pelas leis do Estado de São Paulo, Brasil, e
        qualquer disputa decorrente deste contrato será resolvida nos tribunais
        competentes do Estado de São Paulo.
      </p>
      <p>
        7.2. Se qualquer disposição deste contrato for considerada inválida ou
        inexequível, as demais disposições permanecerão em pleno vigor e efeito.
      </p>
    </Container>
  )
}
