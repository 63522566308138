export const myTheme = {
  colors: {
    backgroundLight: '#b3c5e8',
    backgroundLighter: '#ffffff',
    backgroundDark: '#292C44',
    backgroundDarker: '#232639',
    backgroundBlack: '#1C1E2E',
    backgroundStart: '#292C44',
    backgroundEnd: '#232639',
    textLight: '#7F8EAB',
    textLighter: '#FFFFFF',
    textDark: '#1C1E2E',
    textDarker: '#000000',
    textPrimary: '#FF4A8F',
    primary: '#FF4A8F',
    primaryDark: '#b23364',
    primaryDarker: '#6e1d3c',
    green: '#1dd3b0',
    red: '#e01e37',
    blue: '#4da3ff',
  },
  fontSizes: {
    small: '1.2rem',
    medium: '1.6rem',
    large: '2.0rem',
    xlarge: '2.4rem',
  },
  fontFamilies: {
    mono: "ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono', 'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro', 'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace",
    nunito: "'Nunito', Arial, Helvetica, sans-serif",
    inter: "'Inter', Arial, Helvetica, sans-serif",
  },
}

export type MyTheme = typeof myTheme
