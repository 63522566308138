import { MainPanel } from './MainPanel'
import { Container } from './styles'

export const Categories: React.FC = () => {
  return (
    <Container>
      <MainPanel />
    </Container>
  )
}
