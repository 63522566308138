import styled from 'styled-components'

export const Container = styled.header`
  grid-area: header-user;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 6.4rem;
  max-height: 6.4rem;
  width: 100%;

  .col {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    flex: 1;

    @media (max-width: 730px) {
      padding-left: 6.4rem;
    }
  }
`
