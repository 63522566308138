import { X } from '@phosphor-icons/react'

import { CloseButton, Container } from './styles'

interface IModalHeaderProps {
  title: string
  onClose: () => void
}

export const ModalHeader: React.FC<IModalHeaderProps> = ({
  title,
  onClose,
}) => {
  return (
    <Container>
      <h2>{title}</h2>
      <CloseButton type="button" onClick={onClose}>
        <X size={'3.2rem'} />
      </CloseButton>
    </Container>
  )
}
