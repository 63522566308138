import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import { AdminHome } from './pages/admin/AdminHome'
import { ForgotPassword } from './pages/auth/ForgotPassword'
import { Login } from './pages/auth/Login'
import { RecoverPassword } from './pages/auth/RecoverPassword'
import { SignUp } from './pages/auth/SignUp'
import { Categories } from './pages/Categories'
import { PrivacyTerms } from './pages/docs/PrivacyTerms'
import { UseTerms } from './pages/docs/UseTerms'
import { Error404 } from './pages/Error404'
import { Home } from './pages/Home'
import { Transactions } from './pages/Transactions'
import { AppLayout } from './styles/layouts/AppLayout'
import { AuthLayout } from './styles/layouts/AuthLayout'
import { DocLayout } from './styles/layouts/DocLayout'

export const Router: React.FC = () => {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <AppLayout />,
      children: [
        {
          path: '/',
          element: <Home />,
        },
        {
          path: 'transactions',
          element: <Transactions />,
        },
        {
          path: 'categories',
          element: <Categories />,
        },
      ],
    },
    {
      path: '/admin',
      element: <AppLayout />,
      children: [
        {
          path: '',
          element: <AdminHome />,
        },
      ],
    },
    {
      path: '/auth',
      element: <AuthLayout />,
      children: [
        {
          path: 'login',
          element: <Login />,
        },
        {
          path: 'signup',
          element: <SignUp />,
        },
        {
          path: 'forgot-password',
          element: <ForgotPassword />,
        },
        {
          path: 'recover-password',
          element: <RecoverPassword />,
        },
      ],
    },
    {
      path: '/docs',
      element: <DocLayout />,
      children: [
        {
          path: 'use-terms',
          element: <UseTerms />,
        },
        {
          path: 'privacy-terms',
          element: <PrivacyTerms />,
        },
      ],
    },
    {
      path: '*',
      element: (
        <AppLayout>
          <Error404 />
        </AppLayout>
      ),
    },
  ])

  return <RouterProvider router={router} />
}
