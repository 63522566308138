import styled, { keyframes } from 'styled-components'

const ellipsis1Animation = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
  `
const ellipsis2Animation = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(2.4rem, 0);
  }
  `
const ellipsis3Animation = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`

const containerAnimation = keyframes`
  0% {
    width: 0rem;
  }
  100% {
    width: 7.8rem;
  }
`

export const Container = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7.8rem;
  animation: ${containerAnimation} 0.5s;
  overflow: hidden;

  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 7.8rem;
    height: 4rem;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 1.4rem;
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 0.8rem;
    animation: ${ellipsis1Animation} 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 0.8rem;
    animation: ${ellipsis2Animation} 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 3.2rem;
    animation: ${ellipsis2Animation} 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 5.6rem;
    animation: ${ellipsis3Animation} 0.6s infinite;
  }
`
