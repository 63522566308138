import styled from 'styled-components'

export const Container = styled.label`
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    font-size: 1.6rem;
  }

  input {
    width: 100%;
    height: 4.8rem;
    padding: 0 1.6rem;
    border: none;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 0.8rem;
    margin-top: 0.4rem;
    color: ${(props) => props.theme.colors.textLight};

    &::placeholder {
      color: currentColor;
      opacity: 0.4;
    }
  }

  .error {
    text-align: center;
    display: block;
    margin-top: 0.4rem;
    color: ${(props) => props.theme.colors.red};
  }
`
