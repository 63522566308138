import styled from 'styled-components'

export const Container = styled.label`
  display: flex;
  flex-direction: column;
  width: 100%;

  .input-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .toggle {
    width: 6rem;
    height: 3rem;
    background-color: ${(props) => props.theme.colors.backgroundBlack};
    border-radius: 2rem;
    position: relative;
    cursor: pointer;
    transition: all 0.2s;

    &::after {
      content: '';
      position: absolute;
      top: 0.4rem;
      left: 0.4rem;
      width: 2.2rem;
      height: 2.2rem;
      background-color: ${(props) => props.theme.colors.backgroundDark};
      border-radius: 50%;
      transition: all 0.2s;
    }

    &.active {
      &::after {
        left: calc(100% - 2.6rem);
        background-color: ${(props) => props.theme.colors.primary};
      }
    }
  }

  p {
    font-size: 1.6rem;
  }

  input {
    height: 0;
    width: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0 1.6rem;
    border: none;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 0.8rem;
    margin-top: 0.4rem;
    color: ${(props) => props.theme.colors.textLight};

    &::placeholder {
      color: currentColor;
      opacity: 0.4;
    }
  }

  .error {
    text-align: center;
    display: block;
    margin-top: 0.4rem;
    color: ${(props) => props.theme.colors.red};
  }
`
