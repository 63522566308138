import styled from 'styled-components'

export const Container = styled.section`
  position: absolute;
  background-color: ${(props) => props.theme.colors.backgroundDarker};
  padding: 1.6rem;
  border-radius: 0.8rem;
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.5);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 57.3rem;

  h3 {
    margin-bottom: 0.8rem;
  }
`

export const AddCategoryButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  min-width: 12.7rem;
  max-width: 12.7rem;
  height: 4.8rem;
  cursor: pointer;
  padding: 0.8rem;
  line-height: 1.6rem;
  text-align: center;

  background-color: ${(props) => props.theme.colors.backgroundBlack};
  border-radius: 0.8rem;

  &:hover {
    filter: brightness(0.8);
  }
`

export const NoneCategoryButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  min-width: 12.7rem;
  max-width: 12.7rem;
  height: 4.8rem;
  cursor: pointer;
  padding: 0.8rem;
  line-height: 1.6rem;
  text-align: center;

  background-color: ${(props) => props.theme.colors.backgroundBlack};
  border-radius: 0.8rem;

  &:hover {
    filter: brightness(0.8);
  }
`

export const CategoryList = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 1.1rem;

  & + h4 {
    margin-top: 1.6rem;
  }
`

export const CategoryItemButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  min-width: 12.7rem;
  max-width: 12.7rem;
  height: 4.8rem;
  cursor: pointer;
  padding: 0 0.8rem 0 0;
  text-align: left;

  background-color: ${(props) => props.theme.colors.backgroundBlack};
  border-radius: 0.8rem;

  p {
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:hover {
    filter: brightness(0.8);
  }
`
