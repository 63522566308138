import { ModalHeader } from '../ModalHeader'
import { Container, Row, Button } from './styles'

interface IYesNoModalProps {
  onClose: () => void
  onYes: () => void
  onNo?: () => void
  title: string
  description?: string
}
export const YesNoModal: React.FC<IYesNoModalProps> = ({
  onClose,
  onYes,
  onNo,
  title,
  description,
}) => {
  return (
    <Container>
      <ModalHeader title={title} onClose={onClose} />
      <p>{description}</p>

      <Row>
        <Button
          color="#3a86ff"
          onClick={() => {
            onYes()
            onClose()
          }}
        >
          Sim
        </Button>
        <Button
          color="#e01e37"
          onClick={() => {
            if (onNo) {
              onNo()
            }
            onClose()
          }}
        >
          Não
        </Button>
      </Row>
    </Container>
  )
}
