import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { type ICategory } from '../../../@types/ICategory'
import { type ITransaction } from '../../../@types/ITransaction'
import { useChanges } from '../../../hooks/ChangesContextProvider'
import { useToast } from '../../../hooks/ToastContextProvider'
import { useWallet } from '../../../hooks/WalletContextProvider'
import { api } from '../../../services/api'
import { formatMoney } from '../../../services/utils/inputs/formatMoney'
import { parseMoney } from '../../../services/utils/parseMoney'
import { parseNumber } from '../../../services/utils/parseNumber'
import { Button } from '../../Button'
import { CategorySelect } from '../../Inputs/CategorySelect'
import { Input } from '../../Inputs/Input'
import { InputSelect } from '../../Inputs/InputSelect'
import { InputWithFormater } from '../../Inputs/InputWithFormater'
import { WalletSelect } from '../../Inputs/WalletSelect'
import { Loading } from '../../Loading'
import { ModalHeader } from '../ModalHeader'
import { Container, LoadingPanel, Row } from './styles'
import { formSchema, type IFormData } from './validation'

interface IAddTransactionModalProps {
  onClose: () => void
  id?: string
}

export const AddTransactionModal: React.FC<IAddTransactionModalProps> = ({
  onClose,
  id,
}) => {
  const methods = useForm<IFormData>({
    defaultValues: {
      type: 'OUTCOME',
      value: 'R$ 0,00',
      paidIn: new Date().toISOString().split('T')[0],
    },
    resolver: formSchema,
  })
  const type = methods.watch('type')
  const [loading, setLoading] = useState(id !== undefined)
  const { currentWallet } = useWallet()
  const { addToast } = useToast()
  const { handleChange } = useChanges()

  const submit = async (data: IFormData) => {
    if (!currentWallet?.id) {
      return
    }
    setLoading(true)

    let categoryId
    if (data.category) {
      const category = JSON.parse(data.category)
      delete data.category
      if (category?.id) {
        categoryId = category.id
      } else {
        try {
          if (!category) {
            throw new Error('Sem categorias')
          }
          const newCategory = await api.post<ICategory>('/categories', category)
          handleChange('categories')
          categoryId = newCategory.data.id
        } catch (err) {
          categoryId = undefined
        }
      }
    }

    try {
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      const description = data.description || undefined
      const body = {
        type: data.type || 'OUTCOME',
        value: parseNumber(data.value),
        title: data.title,
        paidIn: new Date(),
        description,
        categoryId,
      }
      const walletId = data.wallet || currentWallet.id

      if (id) {
        await api.patch<ITransaction>(
          `/wallet/${walletId}/transactions/${id}`,
          body,
        )
        addToast({
          title: 'Sucesso!',
          description: 'A movimentação foi editada com sucesso!',
          type: 'success',
          time: 3,
        })
      } else {
        await api.post<ITransaction>(`/wallet/${walletId}/transactions`, body)
        addToast({
          title: 'Sucesso!',
          description: 'A movimentação foi inserida com sucesso!',
          type: 'success',
          time: 3,
        })
      }

      handleChange('transactions')
      setLoading(false)
      onClose()
    } catch {
      addToast({
        title: 'Oops...',
        description:
          'Parece que houve um problema ao inserir a movimentação...',
        type: 'error',
        time: 5,
      })
      setLoading(false)
    }
  }

  // Focus on value input
  useEffect(() => {
    methods.setFocus('value')
  }, [methods])

  // Get transaction data
  useEffect(() => {
    const getTransaction = async () => {
      if (id && currentWallet?.id) {
        setLoading(true)
        const transaction = await api.get<ITransaction>(
          `/wallet/${currentWallet?.id}/transactions/${id}`,
        )
        methods.reset({
          category: JSON.stringify(transaction.data.category),
          description: transaction.data.description,
          title: transaction.data.title,
          type: transaction.data.type,
          value: parseMoney(transaction.data.value),
          wallet: transaction.data.wallet?.id,
        })
        setLoading(false)
      }
    }

    getTransaction().catch(console.error)
  }, [id, currentWallet?.id, methods])

  return (
    <FormProvider {...methods}>
      <Container onSubmit={methods.handleSubmit(submit)}>
        <ModalHeader title="Adicionar movimentação" onClose={onClose} />
        <Row>
          <CategorySelect
            label="Categoria"
            name="category"
            type={type}
            withRecommendations
          />
          <WalletSelect label="Carteira" name="wallet" required />
        </Row>
        <Row>
          <InputWithFormater
            name="value"
            label="Valor"
            placeholder="Qual o valor?"
            align="right"
            formatter={formatMoney}
            className={type.toLowerCase()}
            required
          />
          <InputSelect
            name="type"
            label="Tipo da transação"
            options={[
              { value: 'OUTCOME', label: 'Saída' },
              { value: 'INCOME', label: 'Entrada' },
            ]}
            required
          />
        </Row>
        <Row>
          <Input
            name="title"
            label="Título"
            placeholder="Dê um nome!"
            required
          />
          <Input
            name="paidIn"
            type="date"
            label="Data"
            placeholder="Informe a data"
          />
        </Row>
        <Input name="description" label="Descrição" placeholder="Descreva!" />
        <Button type="submit" disabled={loading}>
          {id ? 'Editar movimentação' : 'Adicionar movimentação'}
        </Button>
        {loading && (
          <LoadingPanel>
            <Loading />
          </LoadingPanel>
        )}
      </Container>
    </FormProvider>
  )
}
