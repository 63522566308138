import axios from 'axios'

const token = localStorage.getItem('@okaneko/token')

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: token ? `Bearer ${token}` : undefined,
  },
})
