import styled, { keyframes } from 'styled-components'

const shadowAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const Container = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  background-color: rgba(0, 0, 0, 0.6);

  animation: ${shadowAnimation} 0.2s;
`
