import { useRef, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import {
  getRecommendedIcons,
  type IIconsName,
} from '../../../services/utils/getRecommendedIcons'
import { Icon } from './Icon'
import {
  Container,
  IconsList,
  PreviewIconButton,
  SelectIconButton,
} from './styles'

interface IOwnProps {
  label: string
  name: string
  required?: boolean
  align?: 'left' | 'center' | 'right'
}

type IInputIconProps = IOwnProps & React.HTMLProps<HTMLInputElement>

export const InputIcon: React.FC<IInputIconProps> = ({
  label,
  required,
  name,
  align = 'left',
  color,
  ...props
}) => {
  const { register, setValue, watch } = useFormContext()
  const icon = watch(name)
  const iconListRef = useRef<HTMLDivElement>(null)
  const [isListOpen, setIsListOpen] = useState(false)
  const recommendedIcons = getRecommendedIcons()

  const handleSelect = (iconName: IIconsName) => {
    setValue(name, iconName)
  }

  useEffect(() => {
    setValue(name, icon)
  }, [icon, name, setValue])

  return (
    <Container align={align}>
      <p>
        <strong>
          {label} {required && '*'}
        </strong>
      </p>
      <input type="hidden" required={required} {...props} {...register(name)} />
      <PreviewIconButton
        color={color}
        onFocus={() => {
          setIsListOpen(true)
        }}
        onBlur={() => {
          setIsListOpen(false)
        }}
        type="button"
      >
        <Icon name={icon as IIconsName} />
      </PreviewIconButton>

      {isListOpen && (
        <IconsList ref={iconListRef}>
          {recommendedIcons.map((icon) => (
            <SelectIconButton
              key={icon}
              onMouseDown={() => {
                handleSelect(icon)
              }}
              type="button"
            >
              <Icon name={icon} />
            </SelectIconButton>
          ))}
        </IconsList>
      )}
    </Container>
  )
}
