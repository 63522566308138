import { createContext, useContext, useState } from 'react'

import { type IToast } from '../../@types/IToast'
import { ToastList } from './ToastList'

interface IToastContextProviderProps {
  children: React.ReactNode
}

interface ICreateContext {
  toasts: IToast[]
  addToast: (
    toast: Omit<IToast, 'id' | 'timeoutCode' | 'timeout' | 'timestart'>,
  ) => string
  removeToast: (toastId: string) => void
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const ToastContext = createContext({} as ICreateContext)

export const ToastContextProvider: React.FC<IToastContextProviderProps> = ({
  children,
}) => {
  const [toasts, setToasts] = useState<IToast[]>([])

  function addToast({
    title,
    description,
    type,
    time = 6,
  }: Omit<IToast, 'id' | 'timeoutCode' | 'timeout' | 'timestart'>) {
    const id = (Math.random() + 1).toString(36).substring(7)

    const toast: IToast = {
      id,
      title,
      description,
      time,
      type,
      timeoutCode: setTimeout(() => {
        setToasts((state) => {
          return [...state.filter((item) => item.id === id)]
        })
      }, time * 1000),
    }

    setToasts((state) => {
      return [...state, toast]
    })

    return id
  }

  function removeToast(toastId: string) {
    setToasts((state) => {
      return [...state.filter((item) => item.id !== toastId)]
    })
  }

  return (
    <ToastContext.Provider value={{ toasts, addToast, removeToast }}>
      {children}
      <ToastList toasts={toasts} />
    </ToastContext.Provider>
  )
}

export const useToast = () => {
  const context = useContext(ToastContext)

  if (!context) {
    throw new Error('Theres a problem with ToastContext')
  }

  return context
}
