import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { Button } from '../../../components/Button'
import { Input } from '../../../components/Inputs/Input'
import { useAuth } from '../../../hooks/AuthContextProvider'
import { Container, Title } from './styles'

interface IFormData {
  email: string
  password: string
}

export const Login: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const methods = useForm<IFormData>()
  const { doLogin } = useAuth()

  async function handleSubmit(data: IFormData) {
    try {
      setLoading(true)
      await doLogin({ email: data.email, password: data.password })
    } catch {
      methods.setError('password', {
        type: 'validate',
        message: 'Nome ou senha incorretos.',
      })
    }
    setLoading(false)
  }

  return (
    <Container>
      <Title>Entrar</Title>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <Input
            name="email"
            label="E-mail"
            placeholder="Digite o seu e-mail"
          />
          <Input
            name="password"
            label="Senha"
            type="password"
            placeholder="Digite a sua senha"
          />
          <Button type="submit" loading={loading}>
            Entrar
          </Button>
        </form>
        <Link to="/auth/signup">
          Não tem uma conta? <u>Criar uma</u>
        </Link>
        <Link to="/auth/forgot-password">
          Esqueceu a senha? <u>Recuperar senha</u>
        </Link>
      </FormProvider>
    </Container>
  )
}
