import { useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { v4 as uuid } from 'uuid'

import { Prohibit } from '@phosphor-icons/react'

import { type ICategory } from '../../../@types/ICategory'
import { type Optional } from '../../../@types/Optional'
import { useChanges } from '../../../hooks/ChangesContextProvider'
import { useModal } from '../../../hooks/ModalContextProvider'
import { api } from '../../../services/api'
import { CategoryIcon } from '../../CategoryIcon'
import { ListCategoriesModal } from '../../Modals/ListCategoriesModal'
import { Container, SelectedCategoryButton } from './styles'

interface IOwnProps {
  label: string
  name: string
  type?: string | null
  withRecommendations?: boolean
  noSubcategory?: boolean
}

type ICategorySelectProps = IOwnProps &
  Omit<React.HTMLProps<HTMLInputElement>, 'value' | 'type'>

export const CategorySelect: React.FC<ICategorySelectProps> = ({
  label,
  placeholder,
  name,
  required,
  type,
  withRecommendations,
  noSubcategory,
  disabled,
  ...props
}) => {
  const uniqueId = useRef<string | null>(null)
  const { register, setValue, watch } = useFormContext()
  const [selected, setSelected] = useState<ICategory | null>(null)
  const [userCategories, setUserCategories] = useState<ICategory[]>([])
  const { addModal, removeModal } = useModal()
  const { changes } = useChanges()
  const value = watch(name)

  const handleSelectCategory = (category: Optional<ICategory, 'id'> | null) => {
    setValue(name, JSON.stringify(category))
    removeModal(`list-categories-select-modal-${uniqueId.current ?? ''}`)
  }

  const handleOpenListCategoriesModal = () => {
    addModal({
      id: `list-categories-select-modal-${uniqueId.current ?? ''}`,
      content: (
        <ListCategoriesModal
          preloadedCategories={userCategories}
          onSelectCategory={handleSelectCategory}
          showRecommendations={withRecommendations}
          noSubcategory={noSubcategory}
          type={type}
        />
      ),
    })
  }

  useEffect(() => {
    async function getUserCategories() {
      const { data } = await api.get<ICategory[]>('/categories')

      setUserCategories(data)
    }

    getUserCategories().catch(console.error)
  }, [changes.categories])

  useEffect(() => {
    if (!uniqueId.current) {
      uniqueId.current = uuid()
    }
  }, [])

  useEffect(() => {
    if (!value) return
    const parsedValue = JSON.parse(value)
    if (!selected) {
      setSelected(parsedValue)
    } else if (selected?.id !== parsedValue.id) {
      setSelected(parsedValue)
    }
  }, [value, selected])

  return (
    <Container>
      <p>
        <strong>
          {label} {required && '*'}
        </strong>
      </p>
      <SelectedCategoryButton
        className={!selected ? 'none-selected' : ''}
        type="button"
        onClick={handleOpenListCategoriesModal}
        disabled={disabled}
      >
        {!selected && (
          <>
            <Prohibit size={'2.4rem'} />
            <p>{placeholder ?? 'Nenhuma categoria selecionada'}</p>
          </>
        )}
        {selected && (
          <>
            <CategoryIcon category={selected} />
            <p>{selected.title}</p>
          </>
        )}
      </SelectedCategoryButton>
      <input type="hidden" required={required} {...props} {...register(name)} />
    </Container>
  )
}
