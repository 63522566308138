import * as Yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'

export interface IFormData {
  wallet: string
  type: string
  value: string
  title: string
  description?: string | null
  category?: string | null
  paidIn: string
}

export const formSchema = yupResolver(
  Yup.object().shape({
    wallet: Yup.string().required('Selecione uma carteira'),
    type: Yup.string().required('Selecione o tipo da movimentação'),
    value: Yup.string().required('Informe o valor da movimentação'),
    title: Yup.string().required('Informe o título da movimentação'),
    paidIn: Yup.string().required('Informe a data da movimentação'),
    description: Yup.string().optional().nullable(),
    category: Yup.string().optional().nullable(),
  }),
)
