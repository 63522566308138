import { useFormContext } from 'react-hook-form'

import { Container } from './styles'

interface IOwnProps {
  label: string
  name: string
}

type IInputProps = IOwnProps & React.HTMLProps<HTMLInputElement>

export const Input: React.FC<IInputProps> = ({
  label,
  required,
  name,
  ...props
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()
  const error = errors[name]?.message?.toString() ?? ''

  return (
    <Container>
      <p>
        <strong>
          {label} {required && '*'}
        </strong>
      </p>
      <input {...register(name)} {...props} />
      {error && <span className="error">{error}</span>}
    </Container>
  )
}
