import { useEffect, useState } from 'react'

import { format } from 'date-fns'

import { type IMetrics } from '../../../@types/IMetrics'
import { api } from '../../../services/api'
import { Container, NumberBox } from './styles'

export const AdminHome: React.FC = () => {
  const [metrics, setMetrics] = useState<IMetrics | null>(null)
  useEffect(() => {
    const fetchMetrics = async () => {
      const response = await api.get<IMetrics>('/admin/metrics')

      setMetrics(response.data)
    }

    fetchMetrics().catch(console.error)
  }, [])

  if (!metrics) {
    return <p>Carregando...</p>
  }

  return (
    <Container>
      <NumberBox>
        <h2>Usuários</h2>
        <p>{metrics.totalUsers}</p>
      </NumberBox>
      <NumberBox>
        <h2>Usuários ativos</h2>
        <p>{metrics.activeUsers}</p>
      </NumberBox>
      <NumberBox>
        <h2>Carteiras</h2>
        <p>{metrics.totalWallets}</p>
      </NumberBox>
      <NumberBox>
        <h2>Movimentações</h2>
        <p>{metrics.totalTransactions}</p>
      </NumberBox>
      <NumberBox>
        <h2>Categorias</h2>
        <p>{metrics.totalCategories}</p>
      </NumberBox>
      <NumberBox>
        <h2>Ultima alteração</h2>
        <p className="date">
          {format(new Date(metrics.lastModifiedDate), 'dd/MM/yyyy HH:mm')}
        </p>
      </NumberBox>
      <NumberBox>
        <h2>Ultimo login</h2>
        <p className="date">
          {format(new Date(metrics.lastLoginDate), 'dd/MM/yyyy HH:mm')}
        </p>
      </NumberBox>
      <NumberBox>
        <h2>Ultimo logout</h2>
        <p className="date">
          {format(new Date(metrics.lastLogoutDate), 'dd/MM/yyyy HH:mm')}
        </p>
      </NumberBox>
    </Container>
  )
}
