import { useState } from 'react'

import { Bell, Trash } from '@phosphor-icons/react'

import { type INotification } from '../../../../../../@types/INotification'
import { Container, NotificationUl, NotificationItem } from './styles'

export const NotificationList: React.FC = () => {
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false)
  const [notifications] = useState<INotification[]>([])

  return (
    <Container
      onFocus={() => {
        setIsNotificationsOpen(true)
      }}
      onBlur={() => {
        setIsNotificationsOpen(false)
      }}
    >
      <button type="button">
        <Bell size={'2.4rem'} />
      </button>
      <NotificationUl className={isNotificationsOpen ? 'active' : ''}>
        {notifications.map((notification) => (
          <NotificationItem key={notification.id}>
            <button type="button" className="info-button">
              <img src={notification.iconUrl ?? '/logo192.png'} alt="Ícone" />
              <p>{notification.resume}</p>
            </button>
            <button type="button" className="close-button">
              <Trash size={'1.6rem'} />
            </button>
          </NotificationItem>
        ))}

        {notifications.length === 0 && (
          <p className="empty">Opa, tudo limpo por aqui!</p>
        )}
      </NotificationUl>
    </Container>
  )
}
