import { Hooks } from './hooks'
import { Router } from './router'

export const App: React.FC = () => {
  return (
    <Hooks>
      <Router />
    </Hooks>
  )
}
