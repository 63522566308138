import styled from 'styled-components'

export const Container = styled.label`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  p {
    font-size: 1.6rem;
  }

  input {
    width: 100%;
    height: 4.8rem;
    padding: 0 1.6rem;
    border: none;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 0.8rem;
    margin-top: 0.4rem;
    color: ${(props) => props.theme.colors.textLight};
    cursor: pointer;

    &::placeholder {
      color: currentColor;
      opacity: 0.4;
    }
  }

  .icon {
    cursor: pointer;
    pointer-events: none;
    width: 3.2rem;
    height: 3.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0.8rem;
    right: 0.8rem;
  }

  .error {
    text-align: center;
    display: block;
    margin-top: 0.4rem;
    color: ${(props) => props.theme.colors.red};
  }
`

export const SelectContainer = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.backgroundBlack};
  position: absolute;
  top: calc(100% + 0.8rem);
  z-index: 99;
  max-height: 30rem;
  overflow: auto;
  width: 100%;
  border-radius: 0.8rem;
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.2);
`

export const SelectOption = styled.button`
  padding: 0.8rem 1.6rem;
  cursor: pointer;
  border: none;
  background: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`
