import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { X } from '@phosphor-icons/react'

import { type ICategory } from '../../../@types/ICategory'
import { useChanges } from '../../../hooks/ChangesContextProvider'
import { useToast } from '../../../hooks/ToastContextProvider'
import { api } from '../../../services/api'
import { Button } from '../../Button'
import { CategorySelect } from '../../Inputs/CategorySelect'
import { Input } from '../../Inputs/Input'
import { InputColor } from '../../Inputs/InputColor'
import { InputIcon } from '../../Inputs/InputIcon'
import { InputSelect } from '../../Inputs/InputSelect'
import { Loading } from '../../Loading'
import { CloseButton, Container, Header, LoadingPanel, Row } from './styles'
import { formSchema, type IFormData } from './validation'

interface IAddCategoryModalProps {
  onClose: () => void
  noSubcategory?: boolean
  id?: string
}

export const AddCategoryModal: React.FC<IAddCategoryModalProps> = ({
  onClose,
  id,
  noSubcategory,
}) => {
  const methods = useForm<IFormData>({
    resolver: formSchema,
    defaultValues: {
      iconUrl: 'Money',
      color: '#FF4A8F',
    },
  })
  const [loading, setLoading] = useState(false)
  const { handleChange } = useChanges()
  const { addToast } = useToast()

  const color = methods.watch('color')

  const submit = async (data: IFormData) => {
    let categoryId = data.category
    setLoading(true)
    if (data.category) {
      const category = JSON.parse(data.category)
      delete data.category
      console.log(category)
      if (category.id) {
        categoryId = category.id
      } else {
        try {
          if (!category) {
            throw new Error('Sem categorias')
          }
          const newCategory = await api.post<ICategory>('/categories', category)
          handleChange('categories')
          categoryId = newCategory.data.id
        } catch (err) {
          categoryId = undefined
        }
      }
    }

    const body = {
      ...data,
      categoryId,
    }

    try {
      if (!body.description) {
        body.description = undefined
      }
      if (!body.type) {
        body.type = undefined
      }
      if (id) {
        await api.put<ICategory>(`/categories/${id}`, body)
        addToast({
          title: 'Sucesso!',
          description: 'A categoria foi atualizada com sucesso!',
          type: 'success',
          time: 3,
        })
      } else {
        await api.post<ICategory>('/categories', body)
        addToast({
          title: 'Sucesso!',
          description: 'A categoria foi inserida com sucesso!',
          type: 'success',
          time: 3,
        })
      }
      handleChange('categories')
      setLoading(false)
      onClose()
    } catch {
      addToast({
        title: 'Oops...',
        description: 'Parece que houve um problema ao inserir a categoria...',
        type: 'error',
        time: 5,
      })
      setLoading(false)
    }
  }

  // get category data
  useEffect(() => {
    const getCategory = async () => {
      if (!id) return
      const res = await api.get<ICategory>(`/categories/${id}`)
      methods.reset({
        category: JSON.stringify(res.data.category),
        color: res.data.color ?? '#FF4A8F',
        iconUrl: res.data.iconUrl ?? '',
        title: res.data.title,
        type: res.data.type ?? '',
        description: res.data.description ?? '',
      })
    }
    getCategory().catch(console.error)
  }, [id, methods])

  return (
    <FormProvider {...methods}>
      <Container onSubmit={methods.handleSubmit(submit)}>
        <Header>
          <h3>Adicionar categoria</h3>
          <CloseButton onClick={onClose}>
            <X size={'3.2rem'} />
          </CloseButton>
        </Header>
        <Row>
          <InputColor name="color" label="Cor" />
          <InputIcon name="iconUrl" label="Ícone" color={color} />
          <CategorySelect
            name="category"
            label="Subcategoria dê"
            disabled={noSubcategory}
            withRecommendations
            noSubcategory
          />
        </Row>
        <Row>
          <Input
            name="title"
            label="Título"
            placeholder="Dê um nome pra categoria!"
            required
          />
          <InputSelect
            name="type"
            label="Tipo"
            placeholder="É uma categoria para entradas ou saídas?"
            options={[
              { label: 'Entradas e Saídas', value: '' },
              { label: 'Saídas', value: 'OUTCOME' },
              { label: 'Entradas', value: 'INCOME' },
            ]}
          />
        </Row>
        <Input name="description" label="Descrição" placeholder="Descreva!" />
        <Button type="submit" loading={loading}>
          {id ? 'Editar Categoria' : 'Criar Categoria'}
        </Button>

        {loading && (
          <LoadingPanel>
            <Loading />
          </LoadingPanel>
        )}
      </Container>
    </FormProvider>
  )
}
