import { createContext, useContext, useEffect, useState } from 'react'

import { jwtDecode } from 'jwt-decode'

import { LoadingPage } from '../../pages/LoadingPage'
import { api } from '../../services/api'

export interface IAuthWallet {
  id: number
  name: number
}

export interface IAuthUser {
  userId: string
  email: string
  role: 'USER' | 'PREMIUM' | 'ADMIN'
  name: string
  firstName: string
  lastName: string
  avatarUrl: string
  wallets: IAuthWallet[]
}

export type IAuthUserPayload = Omit<IAuthUser, 'userId'> & {
  sub: string
  iat: number
  exp: number
}

interface IDoLoginParams {
  email: string
  password: string
}
interface IAuthProviderData {
  token?: string
  user: IAuthUser
  isLogged: boolean
  loaded: boolean
  doLogin: (data: IDoLoginParams) => Promise<void>
  doLogout: () => void
}

const AuthContext = createContext<IAuthProviderData>({} as any)

interface IAuthProviderProps {
  children?: React.ReactNode
}

function getUserFromLocalStorage() {
  const token = localStorage.getItem('@okaneko:token')
  if (token) {
    const decoded = jwtDecode<IAuthUserPayload>(token)
    return {
      userId: decoded.sub,
      email: decoded.email,
      role: decoded.role,
      name: decoded.name,
      firstName: decoded.firstName,
      lastName: decoded.lastName,
      avatarUrl: decoded.avatarUrl,
      wallets: decoded.wallets,
    }
  }
  return {} as unknown as IAuthUser
}

function getTokenFromLocalStorage() {
  const token = localStorage.getItem('@okaneko:token')
  if (token) {
    return token
  }
  return undefined
}

function getIsLoggedFromLocalStorage() {
  const token = localStorage.getItem('@okaneko:token')
  if (token) {
    const decoded = jwtDecode(token)
    if (!decoded?.exp) return false
    const now = Date.now()
    const isLogged = decoded.exp * 1000 > now
    return isLogged
  }
  return false
}

function getIsLoaded() {
  if (!getIsLoggedFromLocalStorage()) {
    return true
  }
  return false
}

export const AuthContextProvider: React.FC<IAuthProviderProps> = ({
  children,
}) => {
  const [loaded, setLoaded] = useState(getIsLoaded)
  const [token, setToken] = useState<string | undefined>(
    getTokenFromLocalStorage,
  )
  const [user, setUser] = useState<IAuthUser>(getUserFromLocalStorage)
  const [isLogged, setIsLogged] = useState(getIsLoggedFromLocalStorage)

  const doLogin = async (data: IDoLoginParams) => {
    const response = await api.post('/auth/login', data)
    const { accessToken } = response.data as {
      accessToken: string
    }
    const payload = jwtDecode<IAuthUserPayload>(accessToken)
    if (!payload) return

    localStorage.setItem('@okaneko:token', accessToken)
    setToken(accessToken)
    setUser({
      userId: payload.sub,
      email: payload.email,
      role: payload.role,
      name: payload.name,
      firstName: payload.firstName,
      lastName: payload.lastName,
      avatarUrl: payload.avatarUrl,
      wallets: payload.wallets,
    })
    setIsLogged(true)
    if (accessToken) {
      api.defaults.headers.Authorization = `Bearer ${accessToken}`
    }
  }
  const doLogout = () => {
    localStorage.removeItem('@okaneko:token')
    setToken(undefined)
    setUser({} as unknown as IAuthUser)
    setIsLogged(false)
    setLoaded(true)
  }

  useEffect(() => {
    function verifyToken() {
      if (token) {
        const decoded = jwtDecode(token)
        if (!decoded?.exp) return
        const now = Date.now()
        const isLogged = decoded.exp * 1000 > now
        if (!isLogged) {
          doLogout()
        }
        setLoaded(true)
      }
    }

    verifyToken()
    const interval = setInterval(verifyToken, 5000)

    if (token) {
      api.defaults.headers.Authorization = `Bearer ${token}`
    }

    return () => {
      clearInterval(interval)
    }
  }, [token])

  return (
    <AuthContext.Provider
      value={{ token, user, isLogged, doLogin, doLogout, loaded }}
    >
      {loaded ? <>{children}</> : <LoadingPage />}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}
