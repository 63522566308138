'use client'

import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  :root{
    font-size: 62.5%;
  }

  @media (max-height: 732px) {
    :root{
      font-size: 50%;
    }
  }
  @media (max-height: 600px) {
    :root{
      font-size: 40%;
    }
  }
  @media (max-height: 450px) {
    :root{
      font-size: 30%;
    }
  }
      
  html,
  body {
    max-width: 100vw;
    overflow-x: hidden;

   color-scheme: dark;
  }

  body {
    min-height: 100vh;
    background: linear-gradient(
        to bottom,
        transparent,
        ${(props) => props.theme.colors.backgroundEnd}
      )
      ${(props) => props.theme.colors.backgroundStart};
  }

  body, button, input, select, textarea {
    color: ${(props) => props.theme.colors.textLight};
    font-family: 'Nunito', Arial, Helvetica, sans-serif;
    font-size: 1.6rem;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  @media (prefers-color-scheme: dark) {
    html {
      color-scheme: dark;
    }
  }

  /* Firefox */
  /* * {
    scrollbar-width: thin;
    scrollbar-color: #7F8EAB #DFE9EB;
  } */

/* Chrome, Edge and Safari */
  *::-webkit-scrollbar {
    width: 1rem;
    width: 1rem;
  }
  *::-webkit-scrollbar-track {
    border-radius: 0.5rem;
    background-color: rgba(0,0,0,0.2);
  }

  *::-webkit-scrollbar-track:hover {
    background-color: rgba(0,0,0,0.2);
  }

  *::-webkit-scrollbar-track:active {
    background-color: rgba(0,0,0,0.2);
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background-color:  #7F8EAB;
  }

  *::-webkit-scrollbar-thumb:hover {
    background-color: #4b5569;
  }

  *::-webkit-scrollbar-thumb:active {
    background-color: #4b5569;
  }

  iframe html {
    background: #000000 !important;
  }
`
