import { Link, Outlet } from 'react-router-dom'

import { Container } from './styles'

interface IDocLayoutProps {
  children?: React.ReactNode
}
export const DocLayout: React.FC<IDocLayoutProps> = ({ children }) => {
  const year = `2023${
    new Date().getFullYear() !== 2023 ? ` - ${new Date().getFullYear()}` : ''
  }`

  return (
    <Container>
      <header>
        <img src="/logo.png" alt="Logo Okaneko" />
        <p>
          Voltar ao <Link to="/">Login</Link>
        </p>
      </header>
      <main>
        {children}
        <Outlet />
      </main>
      <footer>
        <span>Okaneko</span>
        <span>&copy;</span>
        <span className="year">{year}</span>
      </footer>
    </Container>
  )
}
