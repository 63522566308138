import styled from 'styled-components'

export const Container = styled.aside`
  grid-area: lateral;
  display: flex;
  flex-direction: column;

  @media (max-width: 987px) {
    flex: unset;
  }
`

export const Row = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const Header = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.6rem;

  @media (max-width: 460px) {
    flex-direction: column;
    gap: 0.8rem;
  }
`

export const WalletList = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  max-height: 50rem;
  overflow: auto;
`

export const WalletItem = styled.article`
  display: flex;
  padding: 0 0.8rem 0 1.6rem;
  justify-content: space-between;
  align-items: center;
  height: 4.8rem;
  border-radius: 0.8rem;
  background-color: ${(props) => props.theme.colors.backgroundBlack};

  .money {
    &.red {
      color: #e01e37;
    }
    &.green {
      color: #1dd3b0;
    }
  }

  .actions {
    display: flex;
    gap: 0.8rem;
    padding-left: 0.8rem;
    margin-left: 0.8rem;
    border-left: 0.1rem solid #fff2;

    @media (max-width: 460px) {
      padding-left: 0;
      border: none;
    }
  }
`

export const ActionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.2rem;
  height: 3.2rem;
  border: none;
  background-color: ${(props) => props.color};
  border-radius: 0.8rem;
  color: #ffffff;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.2s;

  &:hover {
    opacity: 1;
  }
`

export const WalletButtonEdit = styled.button`
  flex: 1;
  text-align: left;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 500;
  transition: 0.2s;
  display: flex;
  align-items: center;
  gap: 0.8rem;

  &:hover {
    opacity: 0.7;
  }
`
