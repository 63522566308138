import { Container } from './styles'

export const PrivacyTerms: React.FC = () => {
  return (
    <Container>
      <h1>Política de Privacidade do Okaneko</h1>

      <h2>1. Introdução</h2>
      <p>
        Esta Política de Privacidade descreve como a FELIPE DS LIMA TECNOLOGIA
        EM INFORMÁTICA coleta, usa, armazena e protege as informações pessoais
        fornecidas pelos usuários do sistema Okaneko. Ao utilizar o Okaneko,
        você concorda com as práticas descritas nesta política.
      </p>

      <h2>2. Coleta de Dados</h2>
      <p>
        2.1. O Okaneko coleta dados inseridos diretamente pelo usuário para fins
        de controle financeiro, como informações de receitas, despesas, e
        categorias de gastos.
      </p>
      <p>
        2.2. Não coletamos informações pessoais sensíveis, como dados bancários,
        números de cartão de crédito, ou informações de identificação sem o
        consentimento do usuário.
      </p>

      <h2>3. Uso dos Dados</h2>
      <p>
        3.1. Os dados coletados são utilizados exclusivamente para fornecer,
        manter e melhorar os serviços do Okaneko, permitindo ao usuário
        visualizar e gerenciar suas finanças pessoais ou empresariais.
      </p>
      <p>
        3.2. A FELIPE DS LIMA TECNOLOGIA EM INFORMÁTICA não manipula, transfere
        ou realiza transações financeiras em nome do usuário. O Okaneko é uma
        ferramenta de visualização e controle, sem movimentação direta de
        dinheiro.
      </p>

      <h2>4. Compartilhamento de Dados</h2>
      <p>
        4.1. Os dados do usuário não serão vendidos, trocados ou compartilhados
        com terceiros, exceto conforme necessário para:
      </p>
      <ul>
        <li>Cumprir com obrigações legais;</li>
        <li>
          Proteger os direitos e a segurança da FELIPE DS LIMA TECNOLOGIA EM
          INFORMÁTICA e de seus usuários;
        </li>
        <li>Prevenir fraudes e problemas de segurança.</li>
      </ul>
      <p>
        4.2. Em caso de integração com serviços de terceiros, será solicitado
        consentimento explícito do usuário.
      </p>

      <h2>5. Segurança dos Dados</h2>
      <p>
        5.1. Adotamos medidas de segurança administrativas, técnicas e físicas
        para proteger os dados dos usuários contra acesso não autorizado, uso
        indevido, perda ou alteração.
      </p>
      <p>
        5.2. Apesar dos esforços para proteger as informações, nenhum método de
        transmissão de dados pela internet ou armazenamento eletrônico é 100%
        seguro, e não podemos garantir a segurança absoluta.
      </p>

      <h2>6. Retenção de Dados</h2>
      <p>
        6.1. Os dados inseridos no Okaneko serão mantidos enquanto o usuário
        continuar a utilizar o Sistema.
      </p>
      <p>
        6.2. O usuário pode solicitar a exclusão dos seus dados a qualquer
        momento, cessando o uso do Okaneko ou entrando em contato com a FELIPE
        DS LIMA TECNOLOGIA EM INFORMÁTICA.
      </p>

      <h2>7. Direitos dos Usuários</h2>
      <p>
        7.1. O usuário tem o direito de acessar, corrigir, ou excluir seus dados
        armazenados no Okaneko.
      </p>
      <p>
        7.2. Para exercer esses direitos, o usuário deve entrar em contato com
        FELIPE DS LIMA TECNOLOGIA EM INFORMÁTICA através dos canais de
        atendimento disponíveis.
      </p>

      <h2>8. Alterações na Política de Privacidade</h2>
      <p>
        8.1. Esta Política de Privacidade pode ser atualizada a qualquer momento
        para refletir mudanças nas práticas do Okaneko ou para atender a
        requisitos legais.
      </p>
      <p>
        8.2. As alterações serão notificadas aos usuários através do Sistema ou
        por outros meios apropriados.
      </p>

      <h2>9. Contato</h2>
      <p>
        Se você tiver dúvidas ou preocupações sobre esta Política de
        Privacidade, entre em contato com FELIPE DS LIMA TECNOLOGIA EM
        INFORMÁTICA (CNPJ: 32.864.763/0001-77) através do e-mail{' '}
        <a href="mailto:contato@felipedslima.com.br">
          contato@felipedslima.com.br
        </a>
        .
      </p>
    </Container>
  )
}
