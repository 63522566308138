import { useEffect, useRef, useState } from 'react'

import { Chart } from 'chart.js/auto'

import { useChanges } from '../../../hooks/ChangesContextProvider'
import { useWallet } from '../../../hooks/WalletContextProvider'
import { api } from '../../../services/api'
import { myTheme } from '../../../styles/theme'
import { Loading } from '../../Loading'
import { Container } from './styles'

interface IValue {
  balance: number
  income: number
  outcome: number
  month: number
  year: number
}

export const ExpensesByPeriodChart: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [values, setValues] = useState<IValue[]>([])
  const expensesPeriodCanvasRef = useRef<HTMLCanvasElement>(null)
  const chartRef = useRef<Chart | null>(null)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const { changes } = useChanges()
  const { currentWallet } = useWallet()

  useEffect(() => {
    const outputs = values.map((value) => value.outcome)
    const incomes = values.map((value) => value.income)
    const balance = values.map((value) => value.balance)
    const labels = values.map(
      (value) => `${value.month.toString().padStart(2, '0')}/${value.year}`,
    )

    if (!isLoading) {
      chartRef.current = new Chart('expenses-by-period-canvas', {
        type: 'line',
        data: {
          labels,
          datasets: [
            {
              label: 'Saídas',
              data: outputs,
              borderColor: myTheme.colors.red,
              backgroundColor: myTheme.colors.red,
            },
            {
              label: 'Entradas',
              data: incomes,
              borderColor: myTheme.colors.green,
              backgroundColor: myTheme.colors.green,
            },
            {
              label: 'Balanço',
              data: balance,
              borderColor: myTheme.colors.blue,
              backgroundColor: myTheme.colors.blue,
            },
          ],
        },
        options: {
          scales: { y: { min: 0 } },
          responsive: true,
        },
      })
    }

    function resizeChart() {
      if (chartRef.current) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
        }
        timeoutRef.current = setTimeout(() => {
          chartRef.current?.resize()
        }, 200)
      }
    }

    window.addEventListener('resize', resizeChart)

    return () => {
      window.removeEventListener('resize', resizeChart)
      chartRef.current?.destroy()
    }
  }, [isLoading, values])

  useEffect(() => {
    async function getExpensesByPeriod() {
      if (!currentWallet?.id) return
      setIsLoading(true)
      const response = await api.get<IValue[]>(
        `/wallet/${currentWallet.id}/balance/dashboard/expenses-by-period`,
      )
      if (Array.isArray(response.data) && response.data.length === 12) {
        setValues(response.data)
      }
      setIsLoading(false)
    }

    getExpensesByPeriod().catch(console.error)
  }, [changes.transactions, currentWallet])

  return (
    <Container>
      <div className="expenses-by-period">
        <h3>Gastos por período</h3>
        <canvas
          ref={expensesPeriodCanvasRef}
          id="expenses-by-period-canvas"
        ></canvas>
        {isLoading && (
          <div className="loading-panel">
            <Loading />
          </div>
        )}
      </div>
    </Container>
  )
}
