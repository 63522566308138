import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;

  h4 {
    margin-bottom: 0.8rem;
  }

  p {
    font-size: 1.6rem;
  }
`

export const SelectedCategoryButton = styled.button`
  margin-top: 0.4rem;
  width: 100%;
  flex: 1;
  height: 4.8rem;
  max-height: 4.8rem;
  background-color: ${(props) => props.theme.colors.backgroundBlack};
  border-radius: 0.8rem;
  border: none;
  cursor: pointer;
  text-align: left;

  display: flex;
  align-items: center;
  gap: 0.8rem;

  &.none-selected {
    padding: 1.6rem;
  }

  &:hover {
    filter: brightness(0.8);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background-color: ${(props) => props.theme.colors.textDark};
    color: ${(props) => props.theme.colors.textLight};
    filter: brightness(1);
  }
`
