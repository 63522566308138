import styled from 'styled-components'

export const Container = styled.section`
  position: relative;

  .color-picker {
    position: absolute;
    z-index: 1;
    background-color: ${(props) =>
      props.theme.colors.backgroundDark} !important;

    label {
      color: ${(props) => props.theme.colors.textLight} !important;
    }

    input {
      background-color: ${(props) =>
        props.theme.colors.backgroundDarker} !important;
      box-shadow: 0 0 0 0.1rem ${(props) => props.theme.colors.backgroundBlack} !important;
    }
  }

  .error {
    text-align: center;
    display: block;
    margin-top: 0.4rem;
    color: ${(props) => props.theme.colors.red};
  }
`

interface IPreviewButtonProps {
  color: string
}
export const PreviewButton = styled.button.withConfig({
  shouldForwardProp: (prop) => prop !== 'color',
})<IPreviewButtonProps>`
  height: 4.8rem;
  width: 4.8rem;
  cursor: pointer;
  border-radius: 0.8rem;
  color: ${(props) => props.color};
  background-color: ${(props) => props.color};
  border: 0.3rem solid ${(props) => props.theme.colors.backgroundBlack};
  margin-top: 0.4rem;
`
