import { Loading } from '../Loading'
import { Container } from './styles'

interface IOwnProps {
  type?: 'button' | 'submit'
  model?: 'normal' | 'small'
  loading?: boolean
}

type IButtonProps = IOwnProps & React.HTMLProps<HTMLButtonElement>

export const Button: React.FC<IButtonProps> = ({
  children,
  model = 'normal',
  type = 'button',
  loading,
  disabled,
  ...props
}) => {
  return (
    <Container
      type={type}
      model={model}
      {...props}
      disabled={!!disabled || loading}
    >
      {children} {loading && <Loading />}
    </Container>
  )
}
