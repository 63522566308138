import { Link, Navigate, Outlet } from 'react-router-dom'

import { useAuth } from '../../../hooks/AuthContextProvider'
import { Box, Container } from './styles'

export interface IAuthLayout {
  children?: React.ReactNode
}

export const AuthLayout: React.FC<IAuthLayout> = ({ children }) => {
  const { token } = useAuth()

  if (token) {
    return <Navigate to="/" />
  }

  return (
    <Container>
      <img src="/logo.png" alt="Logo do Okaneko" />
      <Box>
        <Outlet />
        {children}
      </Box>
      <footer>
        <span>Okaneko</span>
        <span>&copy;</span>
        <span>2023 - {new Date().getFullYear()}</span>

        <div className="terms">
          <Link to="/docs/use-terms">Termos de uso</Link>
          <Link to="/docs/privacy-terms">Política de privacidade</Link>
        </div>
      </footer>
    </Container>
  )
}
