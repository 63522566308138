import styled from 'styled-components'

export const Container = styled.div`
  padding: 0.8rem;
  min-height: 5.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 1.6rem;
  gap: 1.6rem;

  .icon {
    background-color: ${(props) => props.theme.colors.backgroundDarker};
    border-radius: 0.8rem;
  }

  .category-info {
    flex: 1;
  }

  @media (max-width: 460px) {
    flex-direction: column;
    gap: 0.8rem;
    min-height: auto;
  }
`

export const Actions = styled.section`
  border-left: 0.1rem solid #fff2;
  height: 100%;
  display: flex;
  gap: 1.6rem;
  padding-left: 1.6rem;

  @media (max-width: 460px) {
    border: none;
    padding: 0;
  }
`

export const ActionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.8rem;
  height: 4.8rem;
  border: none;
  background-color: ${(props) => props.color};
  border-radius: 0.8rem;
  color: #ffffff;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.2s;

  &:hover {
    opacity: 1;
  }
`
