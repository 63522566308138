import { useEffect, useState } from 'react'

import { type ITransaction } from '../../../@types/ITransaction'
import { Button } from '../../../components/Button'
import { AddTransactionModal } from '../../../components/Modals/AddTransactionModal'
import { Transaction } from '../../../components/Transaction'
import { useChanges } from '../../../hooks/ChangesContextProvider'
import { useModal } from '../../../hooks/ModalContextProvider'
import { useWallet } from '../../../hooks/WalletContextProvider'
import { api } from '../../../services/api'
import { Container, Header, TransactionList } from './styles'

interface IRecentTransactionsProps {
  className?: string
}

export const RecentTransactionsPanel: React.FC<IRecentTransactionsProps> = ({
  className,
}) => {
  const [transactions, setTransactions] = useState<ITransaction[]>([])
  const { currentWallet } = useWallet()
  const { addModal, removeModal } = useModal()
  const { changes } = useChanges()

  const handleCloseAddTransactionModal = () => {
    removeModal('modal-add-transaction')
  }

  const handleOpenAddTransactionModal = () => {
    addModal({
      id: 'modal-add-transaction',
      content: <AddTransactionModal onClose={handleCloseAddTransactionModal} />,
    })
  }

  useEffect(() => {
    async function getTransactions() {
      if (!currentWallet?.id) return
      const { data } = await api.get(
        `/wallet/${currentWallet.id}/transactions`,
        {
          params: {
            limit: 10,
          },
        },
      )
      setTransactions(data)
    }
    getTransactions().catch(console.error)
  }, [changes.transactions, currentWallet])

  return (
    <Container className={className}>
      <Header>
        <h3>Movimentações recentes</h3>
        <Button model="small" onClick={handleOpenAddTransactionModal}>
          Adicionar Movimentação
        </Button>
      </Header>

      <TransactionList>
        {transactions.map((transaction) => (
          <Transaction key={transaction.id} data={transaction} />
        ))}
        {!transactions.length && (
          <span className="empty">
            Está muito limpo por aqui, adoraria ver os meus gastos assim também!
          </span>
        )}
      </TransactionList>
    </Container>
  )
}
