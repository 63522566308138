import styled from 'styled-components'

interface IContainerProps {
  useAd: boolean
}

export const Container = styled.section.withConfig({
  shouldForwardProp: (prop) => prop !== 'useAd',
})<IContainerProps>`
  display: grid;
  grid-template-areas: ${(props) => {
    return props.useAd
      ? "'menu header' 'menu content' 'menu footer'"
      : "'menu header' 'menu content'"
  }};
  grid-template-columns: max-content 1fr;
  grid-template-rows: ${(props) =>
    props.useAd ? 'max-content 1fr 8rem' : 'max-content 1fr'};

  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
`

export const Content = styled.main`
  grid-area: content;
  background-color: ${(props) => props.theme.colors.backgroundDarker};
  max-height: 100%;
  display: flex;
  flex-direction: column;
  transition: 0.2s;
  overflow-y: auto;
`

export const Footer = styled.footer`
  grid-area: footer;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;

  @media (max-width: 1680px) {
    .adsense + .adsense {
      display: none !important;
    }
  }
`
