import styled from 'styled-components'

export const Container = styled.section`
  grid-area: panel-main;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  max-width: 90rem;
  width: 100%;
  margin: 0 auto;

  .category-list {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 1.6rem;
    overflow: auto;

    .empty {
      flex: 1;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      opacity: 0.6;
    }
  }

  @media (max-width: 1248px) {
    .category-list {
      max-height: calc(100vh - 40rem);
    }
  }

  @media (max-width: 730px) {
    padding: 0 0.8rem 1.6rem 0;
  }
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.6rem;

  @media (max-width: 460px) {
    flex-direction: column;
    padding: 0;
    text-align: center;
    gap: 0.8rem;
  }
`
