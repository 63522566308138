import { useEffect } from 'react'

import { Container } from './styles'

interface IAdsenseProps {
  adSlot?: string
  format?: 'responsive' | 'fixed'
  size?: {
    x: string
    y: string
  }
}

export const Adsense: React.FC<IAdsenseProps> = ({
  adSlot = '4091471090',
  format = 'responsive',
  size,
}) => {
  useEffect(() => {
    try {
      ;(window.adsbygoogle = window.adsbygoogle || []).push({})
    } catch (e) {}
  }, [])

  return (
    <Container className="adsense">
      <ins
        className="adsbygoogle"
        style={{
          display: 'block',
          width: '100%',
          height: '100%',
          ...{ ...(size ? { width: size.x, height: size.y } : {}) },
        }}
        data-ad-client={process.env.REACT_APP_AD_CLIENT_CODE}
        data-ad-slot={adSlot}
        data-adtest={process.env.NODE_ENV === 'development' ? 'on' : undefined}
        {...(format === 'responsive'
          ? { 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' }
          : {})}
      ></ins>
    </Container>
  )
}
