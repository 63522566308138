import * as Yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'

export interface IFormData {
  name: string
  color?: string
  iconUrl?: string
  isCreditCard?: boolean
  limit?: string
  dueDate?: string
  closeOnWalletId?: string
}

export const formSchema = yupResolver(
  Yup.object().shape({
    name: Yup.string().required(),
    color: Yup.string().optional(),
    iconUrl: Yup.string().optional(),
    isCreditCard: Yup.bool().optional(),
    limit: Yup.string().optional(),
    dueDate: Yup.string().optional(),
    closeOnWalletId: Yup.string().optional(),
  }),
)
