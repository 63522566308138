import styled, { css } from 'styled-components'

export const Container = styled.section`
  grid-area: overview;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`

export const Header = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    font-size: 2rem;
    font-weight: 700;
  }
`

export const Row = styled.section`
  display: flex;
  gap: 1.6rem;
`

interface IBoxProps {
  type?: 'income' | 'outcome' | 'balance'
}

export const Box = styled.section.withConfig({
  shouldForwardProp: (prop) => prop !== 'type',
})<IBoxProps>`
  flex: 1;
  padding: 2.4rem;
  color: ${({ theme }) => theme.colors.textLighter};
  border-radius: 1.6rem;
  white-space: nowrap;

  h4 {
    font-size: 1.8rem;
    font-weight: 500;
    opacity: 0.8;
  }

  .money {
    font-size: 3.2rem;
    text-align: right;
    font-weight: 700;

    @media (max-width: 800px) {
      font-size: 2.4rem;
    }
    @media (max-width: 550px) {
      font-size: 2rem;
    }
  }
  .info {
    font-size: 1.4rem;
    opacity: 0.7;
    text-align: right;

    @media (max-width: 800px) {
      font-size: 1.2rem;
    }
  }

  ${(props) =>
    props.type === 'income' &&
    css`
      background: linear-gradient(90deg, #086375, #1dd3b0);
    `}
  ${(props) =>
    props.type === 'outcome' &&
    css`
      background: linear-gradient(90deg, #880d1e, #e01e37);
    `}
`

export const BalanceBox = styled.section`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 1.6rem;
  position: relative;
  padding: 5rem 2.4rem 2.4rem;
  background: linear-gradient(90deg, #000000, #2e2e2e);
  color: ${({ theme }) => theme.colors.textLighter};
  border-radius: 1.6rem;
  white-space: nowrap;

  h4 {
    position: absolute;
    top: 2.4rem;
    left: 2.4rem;
    opacity: 0.7;
    font-size: 1.8rem;
    font-weight: 500;
  }

  .money-item {
    flex: 1;
  }

  .money {
    font-size: 4rem;
    text-align: right;
    font-weight: 700;

    &.negative {
      color: #e01e37;
    }
  }

  .large .money {
    font-size: 4rem;
  }
  .medium .money {
    font-size: 3.2rem;
  }
  .small .money {
    font-size: 2rem;
  }

  @media (max-width: 800px) {
    .large .money {
      font-size: 3.2rem;
    }
    .medium .money {
      font-size: 2.4rem;
    }
    .small .money {
      font-size: 1.6rem;
    }
  }

  @media (max-width: 550px) {
    .large .money {
      font-size: 1.8rem;
    }
    .medium .money {
      font-size: 1.6rem;
    }
    .small .money {
      font-size: 1.2rem;
    }
  }

  .info {
    opacity: 0.7;
    text-align: right;
    font-size: 1.4rem;
    white-space: nowrap;
  }
`
