import type * as Icons from '@phosphor-icons/react'

export type IIconsName = keyof typeof Icons

export const getRecommendedIcons = (): IIconsName[] => {
  return [
    // Money
    'Money',
    'Bank',
    'PiggyBank',
    'Cardholder',
    'Calculator',
    'Coin',
    'CoinVertical',
    'Coins',
    'CreditCard',
    'CurrencyBtc',
    'CurrencyEth',
    'CurrencyCircleDollar',
    'CurrencyDollar',
    'CurrencyDollarSimple',
    'CurrencyEur',
    'CurrencyJpy',
    'HandCoins',
    'Receipt',
    'ReceiptX',
    'Wallet',
    'ChartBar',
    'TrendDown',
    'TrendUp',
    'ContactlessPayment',
    'Copyright',

    // Buildings
    'House',
    'Buildings',
    'Factory',
    'Garage',
    'Storefront',
    'Warehouse',

    // Food
    'BowlFood',
    'Hamburger',
    'Pizza',
    'BeerBottle',
    'BeerStein',
    'Brandy',
    'Champagne',
    'Coffee',
    'CookingPot',
    'ForkKnife',
    'Knife',
    'IceCream',
    'Martini',
    'Popcorn',
    'Wine',
    'Cake',

    // Transport
    'Airplane',
    'Bicycle',
    'Boat',
    'Bus',
    'Car',
    'CarProfile',
    'Moped',
    'Motorcycle',
    'Person',
    'PersonSimpleRun',
    'SuitcaseRolling',
    'Train',
    'Van',
    'Signpost',
    'RoadHorizon',
    'Boot',
    'PersonSimpleBike',
    'PersonSimpleThrow',
    'SneakerMove',

    // Entertainment
    'FilmStrip',
    'MonitorPlay',
    'FilmReel',
    'ProjectorScreen',
    'VideoCamera',
    'Ticket',
    'MicrophoneStage',
    'Microphone',
    'Equalizer',
    'Guitar',
    'Headphones',
    'PianoKeys',
    'MusicNotes',
    'Books',
    'Notebook',
    'BookOpen',
    'Television',
    'TelevisionSimple',
    'GameController',
    'Sword',
    'Alien',
    'Baseball',
    'Basketball',
    'Club',
    'PuzzlePiece',
    'Skull',
    'SoccerBall',
    'Strategy',
    'DiceFive',
    'PokerChip',
    'MaskHappy',
    'MaskSad',
    'Image',
    'Camera',
    'Tent',

    // Health
    'Heart',
    'Heartbeat',
    'Baby',
    'Bandaids',
    'Barbell',
    'Bed',
    'Brain',
    'Dna',
    'Eyeglasses',
    'FaceMask',
    'FirstAid',
    'FirstAidKit',
    'HandSoap',
    'Lifebuoy',
    'PawPrint',
    'Stethoscope',
    'SwimmingPool',
    'Syringe',
    'TestTube',
    'Thermometer',
    'Tooth',
    'Virus',
    'Wheelchair',

    // Education
    'Chalkboard',
    'ChalkboardTeacher',
    'GraduationCap',
    'Student',
    'Video',
    'Certificate',
    'Medal',

    // Utilities
    'Drop',
    'ToiletPaper',
    'Lightning',
    'Lightbulb',
    'LightbulbFilament',
    'Lamp',
    'Sun',
    'Flashlight',
    'Fire',
    'Flame',
    'Armchair',
    'ChargingStation',
    'FireExtinguisher',
    'Gift',
    'Circuitry',
    'GasPump',
    'GasCan',
    'Campfire',
    'Wall',
    'PaintBrushHousehold',
    'Grains',
    'AddressBook',
    'At',
    'Chat',
    'ChatCircleDots',
    'ChatsCircle',
    'Church',
    'Cross',
    'StarOfDavid',
    'EnvelopeSimple',
    'EnvelopeSimpleOpen',
    'Link',
    'LinkSimple',
    'Megaphone',
    'PaperPlaneTilt',
    'OfficeChair',

    // Telephone / Internet
    'Phone',
    'Radio',
    'RssSimple',
    'SimCard',
    'DeviceMobile',
    'Devices',
    'DesktopTower',
    'Laptop',
    'Cpu',
    'WifiHigh',
    'WifiSlash',
    'Globe',
    'GlobeHemisphereWest',
    'Translate',

    // Ensurance
    'Umbrella',
    'Anchor',
    'Vault',
    'Parachute',
    'TrafficCone',
    'Barricade',
    'Lighthouse',

    // Cloth
    'BaseballCap',
    'CoatHanger',
    'Dress',
    'HighHeel',
    'Pants',
    'ShirtFolded',
    'TShirt',
    'Sneaker',
    'Watch',

    // Care
    'HandHeart',
    'HandsPraying',
    'Handshake',
    'Balloon',

    // Outdoors
    'Mountains',
    'Park',
    'Tree',
    'TreeEvergreen',
    'TreePalm',
    'Cactus',
    'Flower',
    'FlowerLotus',
    'Plant',
    'PottedPlant',
    'Waves',

    // Others
    'CalendarBlank',
    'Calendar',
    'Alarm',
    'ArchiveBox',
    'Archive',
    'Backpack',
    'Bag',
    'Barcode',
    'Basket',
    'Bathtub',
    'Bell',
    'Briefcase',
    'Broom',
    'MapTrifold',
    'Metronome',
    'Note',
    'PaintBrushBroad',
    'Paperclip',
    'Power',
    'ToteSimple',

    // Companies
    'MetaLogo',
    'AmazonLogo',
    'AndroidLogo',
    'DiscordLogo',
    'GoogleDriveLogo',
    'GooglePlayLogo',
    'GooglePodcastsLogo',
    'InstagramLogo',
    'LinkedinLogo',
    'MessengerLogo',
    'MicrosoftOutlookLogo',
    'MicrosoftTeamsLogo',
    'MicrosoftWordLogo',
    'MicrosoftExcelLogo',
    'WindowsLogo',
    'NotionLogo',
    'PatreonLogo',
    'PaypalLogo',
    'RedditLogo',
    'SlackLogo',
    'SnapchatLogo',
    'TelegramLogo',
    'TiktokLogo',
    'TwitchLogo',
    'TwitterLogo',
    'WhatsappLogo',
    'YoutubeLogo',
    'SpotifyLogo',
    'SoundcloudLogo',
    'AppleLogo',
    'ApplePodcastsLogo',
  ]
}
