import styled from 'styled-components'

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  width: 90%;
  max-width: 50rem;
  padding: 3.2rem;
  background-color: ${(props) => props.theme.colors.backgroundDark};
  border-radius: 1.6rem;
  position: relative;
`

export const Row = styled.section`
  display: flex;
  gap: 0.8rem;
`

export const LoadingPanel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.backgroundBlack};
  border-radius: 1.6rem;
  z-index: 10;
  opacity: 0.9;
`
