import { useState, useEffect } from 'react'

import {
  addDays,
  addMonths,
  endOfMonth,
  format,
  getWeeksInMonth,
  isSameDay,
  isSameMonth,
  isToday,
  startOfMonth,
  subDays,
  subMonths,
  setYear,
} from 'date-fns'
import ptBr from 'date-fns/locale/pt-BR'

import { CaretLeft, CaretRight } from '@phosphor-icons/react'

import { getYearsList } from '../../services/utils/getYearsList'
import {
  Container,
  Header,
  HeaderButton,
  DateButton,
  Grid,
  GridItem,
  SelectYear,
  YearOption,
} from './styles'

interface IDate {
  id: string
  day: number
  dayOfWeek: number
  date: Date
  isSelected: boolean
  isToday: boolean
  isThisMonth: boolean
}

export const Calendar: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [dates, setDates] = useState<IDate[]>([])
  const [isYearListActive, setIsYearListActive] = useState(false)
  const selectedDateFormatted = format(selectedDate, "d 'de' MMMM 'de' yyyy", {
    locale: ptBr,
  })
  const years = getYearsList()

  function getClass(date: IDate) {
    const classes = []
    if (date.isSelected) {
      classes.push('selected')
    }
    if (date.isToday) {
      classes.push('today')
    }
    if (!date.isThisMonth) {
      classes.push('other-month')
    }
    return classes.join(' ')
  }

  function nextMonth() {
    setSelectedDate((state) => {
      return addMonths(state, 1)
    })
  }
  function prevMonth() {
    setSelectedDate((state) => {
      return subMonths(state, 1)
    })
  }
  function changeYear(year: number) {
    setSelectedDate((state) => setYear(state, year))
    setIsYearListActive(false)
  }

  useEffect(() => {
    const calendar: IDate[] = []
    const endPrevMonth = endOfMonth(subMonths(selectedDate, 1))
    const startThisMonth = startOfMonth(selectedDate)
    const endThisMonth = endOfMonth(selectedDate)
    const startNextMonth = endOfMonth(addDays(selectedDate, 1))
    const weeks = getWeeksInMonth(selectedDate)

    let currentDate = startOfMonth(startThisMonth)
    let started = false
    for (let y = 0; y < weeks; y++) {
      for (let x = 0; x < 7; x++) {
        if (startThisMonth.getDay() === x) {
          started = true
        }
        if (started && currentDate.getMonth() === endThisMonth.getMonth()) {
          calendar.push({
            id: (Math.random() + 1).toString(36).substring(7),
            day: currentDate.getDate(),
            date: new Date(currentDate.toISOString()),
            dayOfWeek: x,
            isSelected: isSameDay(currentDate, selectedDate),
            isToday: isToday(currentDate),
            isThisMonth: isSameMonth(currentDate, selectedDate),
          })
          currentDate = addDays(currentDate, 1)
        } else {
          const daysToRemove = startThisMonth.getDay() + 1 - x
          let localDay = subDays(endPrevMonth, daysToRemove)
          if (started) {
            localDay = startNextMonth
            startNextMonth.setDate(startNextMonth.getDate() + 1)
          }
          calendar.push({
            id: (Math.random() + 1).toString(36).substring(7),
            day: localDay.getDate(),
            date: new Date(localDay.toISOString()),
            dayOfWeek: x,
            isSelected: false,
            isToday: false,
            isThisMonth: false,
          })
        }
      }
    }

    setDates(calendar)
  }, [selectedDate])

  return (
    <Container>
      <Header>
        <HeaderButton onClick={prevMonth}>
          <CaretLeft size={'2.4rem'} />
        </HeaderButton>
        <DateButton
          onBlur={() => {
            setIsYearListActive(false)
          }}
          onClick={() => {
            setIsYearListActive(true)
          }}
        >
          {selectedDateFormatted}
        </DateButton>
        <HeaderButton onClick={nextMonth}>
          <CaretRight size={'2.4rem'} />
        </HeaderButton>
      </Header>

      <Grid>
        <GridItem className="header">D</GridItem>
        <GridItem className="header">S</GridItem>
        <GridItem className="header">T</GridItem>
        <GridItem className="header">Q</GridItem>
        <GridItem className="header">Q</GridItem>
        <GridItem className="header">S</GridItem>
        <GridItem className="header">S</GridItem>

        {dates.map((date) => (
          <GridItem
            key={date.id}
            className={getClass(date)}
            onClick={() => {
              setSelectedDate(date.date)
            }}
          >
            {date.day}
          </GridItem>
        ))}
      </Grid>

      {isYearListActive && (
        <SelectYear>
          {years.map((year) => (
            <YearOption
              key={year}
              onMouseDown={() => {
                changeYear(year)
              }}
            >
              {year}
            </YearOption>
          ))}
        </SelectYear>
      )}
    </Container>
  )
}
