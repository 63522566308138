import styled from 'styled-components'

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CloseButton = styled.button`
  width: 4.8rem;
  height: 4.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 0.8rem;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`
