import { HeaderPage } from './HeaderPage'
import { HeaderUser } from './HeaderUser'
import { Container } from './styles'

export const Header: React.FC = () => {
  return (
    <Container>
      <HeaderPage />
      <HeaderUser />
    </Container>
  )
}
