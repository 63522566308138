import styled from 'styled-components'

export const Container = styled.article`
  padding: 1.6rem;
  background-color: ${(props) => props.theme.colors.backgroundBlack};
  border-radius: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.2rem;
  width: min-content;
  position: relative;
`

export const Header = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 0.8rem;
`

export const HeaderButton = styled.button`
  width: 3.2rem;
  height: 3.2rem;
  min-width: 3.2rem;
  min-height: 3.2rem;
  background-color: ${(props) => props.theme.colors.backgroundDarker};
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background-color: ${(props) => props.theme.colors.backgroundDark};
  }
`

export const DateButton = styled.button`
  height: 3.2rem;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 0.8rem;
  transition: 0.2s;
  white-space: nowrap;
  width: 100%;

  &:hover {
    background-color: ${(props) => props.theme.colors.backgroundDark};
  }
`

export const Grid = styled.section`
  display: grid;
  gap: 0.8rem;
  grid-template-columns: repeat(7, 3.2rem);
`

export const GridItem = styled.button`
  width: 3.2rem;
  min-width: 3.2rem;
  height: 3.2rem;
  min-height: 3.2rem;
  border: none;
  background: rgba(0, 0, 0, 0);
  border-radius: 50%;
  cursor: pointer;
  transition: 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.8;

  &:hover {
    background: ${(props) => props.theme.colors.backgroundDarker};
  }
  &.other-month {
    opacity: 0.3;
  }
  &.today {
    background: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.textLighter};
    font-weight: 700;
  }
  &.selected {
    background: ${(props) => props.theme.colors.green};
    color: ${(props) => props.theme.colors.textDark};
    opacity: 1;
    font-weight: 700;
  }
  &:hover {
    opacity: 1;
  }
  &.header {
    background: none;
    color: ${(props) => props.theme.colors.textLighter};
    opacity: 1;
    cursor: default;
    font-weight: 700;
  }
`

export const SelectYear = styled.section`
  position: absolute;
  top: 7.2rem;
  bottom: 1.6rem;
  left: 0.8rem;
  right: 0.8rem;
  z-index: 1;
  overflow: auto;

  gap: 1.6rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  padding: 0.8rem;
  background-color: ${(props) => props.theme.colors.backgroundBlack};
  border-radius: 1.6rem;
`

export const YearOption = styled.button`
  flex: 1;
  min-width: 33%;

  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  border-radius: 0.8rem;
  cursor: pointer;
  height: 3.2rem;
  background-color: ${(props) => props.theme.colors.backgroundDark};

  &:hover {
    background-color: ${(props) => props.theme.colors.backgroundDarker};
  }
`
