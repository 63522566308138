import { Loading } from '../../components/Loading'
import { Container } from './styles'

export const LoadingPage: React.FC = () => {
  return (
    <Container>
      <h1>Carregando...</h1>
      <Loading />
    </Container>
  )
}
