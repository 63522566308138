import styled from 'styled-components'

export const Container = styled.article`
  flex: 1;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 50rem;
  grid-template-rows: max-content max-content;
  grid-template-areas: 'main lateral';
  gap: 3.2rem;
  padding: 1.6rem 3.2rem 0.8rem;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr 40rem;
    grid-template-areas: 'lateral' 'main';
  }

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    padding: 1.6rem 3.2rem 0.8rem;
  }

  @media (max-width: 460px) {
    padding: 1.6rem;
  }
`
