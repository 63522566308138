export const getRecommendedColors = () => {
  const colors = [
    '#FF4A8F',
    '#06d6a0',
    '#ffd166',
    '#ef476f',
    '#118ab2',
    '#073b4c',
    '#9b5de5',
    '#f15bb5',
    '#fee440',
    '#00bbf9',
    '#00f5d4',
  ]

  return colors
}
