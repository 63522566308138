import styled from 'styled-components'

export const Container = styled.header`
  grid-area: header-title;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 6.4rem;
  gap: 0.8rem;

  h2 {
    font-size: 2.4rem;
    font-weight: 400;
    color: ${(props) => props.theme.colors.textLight};

    span.username {
      color: ${(props) => props.theme.colors.textLighter};
    }
    span.message span {
      font-family: Arial, Helvetica, sans-serif;
    }
  }

  @media (max-width: 380px) {
    flex-direction: column;
    align-items: start;
  }
`
