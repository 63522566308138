import styled from 'styled-components'

export const Container = styled.section`
  grid-area: lateral;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  overflow: hidden;

  .calendar-row {
    display: flex;
    justify-content: ${(props) =>
      props.theme.useAd ? 'space-between' : 'center'};
    gap: 0.8rem;

    @media (max-width: 1000px) {
      display: none;
    }
  }

  .chart {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  @media (max-width: 1000px) {
    min-height: max-content;
  }
`
