import { Link } from 'react-router-dom'

import styled from 'styled-components'

export const Container = styled.aside`
  grid-area: menu;
  padding: 1.6rem 0;
  display: flex;
  flex-direction: column;
  max-height: fit-content;
  max-height: 100vh;
  align-items: center;
  position: relative;
  transition: 0.2s;
  width: 8rem;

  &.open {
    width: 30rem;
  }

  .spacer {
    display: block;
    flex: 1;
  }

  .copyright {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 0.4rem;
    font-size: 1.4rem;
    line-height: 1.4rem;
  }

  @media (max-width: 1640px) {
    .copyright {
      .year {
        font-size: 1.2rem;
      }
    }
    .adsbygoogle {
      display: none !important;
    }
  }

  @media (max-width: 730px) {
    flex: 1;
    background-color: ${(props) => props.theme.colors.backgroundDark};
    width: 100vw;
    position: fixed;
    top: 0;
    left: -100vw;
    bottom: 0;
    z-index: 9;

    &.open {
      left: 0;
      width: 100vw;
    }
  }
`

export const MenuList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  gap: 0.8rem;
  padding: 0 1.6rem;
  width: 100%;

  @media (max-width: 730px) {
    flex: 2;
    justify-content: center;
  }
`

export const MenuItem = styled.li`
  display: block;
  height: 4.8rem;
  color: ${(props) => props.theme.colors.textLight};
  transition: 0.2s;
  overflow: hidden;

  a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 4.8rem;
    font-weight: 500;
    font-size: 1.6rem;
    white-space: nowrap;

    .icon {
      width: 4.8rem;
      height: 4.8rem;
      min-width: 4.8rem;
      min-height: 4.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.8rem;
    }
  }

  &.active {
    background-color: ${(props) => props.theme.colors.backgroundDarker};
    border-radius: 0.8rem;
    color: ${(props) => props.theme.colors.textPrimary};
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.backgroundDarker};
    border-radius: 0.8rem;
    color: ${(props) => props.theme.colors.textLighter};
  }

  @media (max-width: 730px) {
    height: 5.6rem;

    a {
      height: 5.6rem;
    }
  }
`

export const Logo = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  height: 5.6rem;
  overflow: hidden;
  transition: 0.2s;

  img {
    height: 6.4rem;
    width: 6.4rem;
    transition: 0.2s;
    object-fit: cover;
    object-position: 0.8rem;
    transition: 0.2s;
  }

  &.open {
    img {
      height: 6.4rem;
      width: auto;
      object-position: 0rem;
    }
  }

  @media (max-width: 730px) {
    img {
      display: block;
    }
  }
`

export const MenuHeader = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3.2rem;
  width: 100%;
  padding: 0 1.6rem;
  gap: 0.8rem;

  @media (max-width: 1640px) {
    grid-template-columns: 8rem 1fr;
    justify-content: space-between;
  }
  @media (max-width: 730px) {
    justify-content: space-between;
    width: 100%;
  }

  &.open {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 9.6rem;
  }
`

export const ToggleMenuButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 5.6rem;
  min-width: 5.6rem;
  border-radius: 0.8rem;
  border: none;
  background: transparent;
  cursor: pointer;
  transition: 0.2s;
  border-radius: 50%;
  transition: all 0.2s;

  &:hover {
    background-color: ${(props) => props.theme.colors.backgroundDarker};
  }

  @media (max-width: 730px) {
    background-color: ${(props) => props.theme.colors.backgroundBlack};
    position: absolute;
    top: 2.9rem;
    left: calc(100% + 3.2rem);
    z-index: 999;
    border-radius: 50%;

    &.open {
      position: static;
    }

    &:hover {
      background-color: ${(props) => props.theme.colors.backgroundBlack};
    }
  }

  @media (max-width: 460px) {
    left: calc(100% + 1.6rem);
  }
`
