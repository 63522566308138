import styled from 'styled-components'

export const Container = styled.section`
  min-width: 5.6rem;
  min-height: 5.6rem;
  max-height: 5.6rem;
  width: fit-content;
  position: relative;
  background-color: ${(props) => props.theme.colors.backgroundBlack};
  border-radius: 3.2rem;

  > button {
    display: flex;
    gap: 0.8rem;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.colors.textLight};
    background: rgba(0, 0, 0, 0);
    border: none;
    min-width: 5.6rem;
    min-height: 5.6rem;
    width: fit-content;
    max-height: 5.6rem;
    border-radius: 3.2rem;
    cursor: pointer;
    box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.2);
    padding: 0 1rem 0 0.4rem;

    .user-photo {
      width: 4.8rem;
      height: 4.8rem;
      object-fit: cover;
      border-radius: 50%;
      border: 0.4rem solid ${(props) => props.theme.colors.backgroundDark};
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${(props) => props.theme.colors.backgroundDarker};

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      p {
        font-weight: bold;
      }
    }

    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }
  }
`

export const OptionList = styled.ul`
  list-style: none;
  position: absolute;
  top: calc(100% + 0.4rem);
  right: 0;
  background-color: ${(props) => props.theme.colors.backgroundDark};
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.2);
  min-width: 100%;
  border-radius: 0.8rem;
  overflow: auto;
  max-height: 0;
  z-index: 9;

  &.active {
    max-height: fit-content;
  }
`

export const OptionItem = styled.li`
  min-width: 100%;

  button {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    border: none;
    min-width: 100%;
    padding: 0.8rem 1.6rem;
    background: rgba(0, 0, 0, 0);
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }
  }
`
