import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Link, useSearchParams } from 'react-router-dom'

import { Button } from '../../../components/Button'
import { Input } from '../../../components/Inputs/Input'
import { api } from '../../../services/api'
import { Container, Title } from './styles'

interface IFormData {
  password: string
  confirmPassword: string
}

export const RecoverPassword: React.FC = () => {
  const methods = useForm<IFormData>()
  const [search] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const [sended, setSended] = useState(false)

  async function handleSubmit(data: IFormData) {
    setLoading(true)
    const token = search.get('token') ?? ''
    await api.post('/auth/recover-password', { token, ...data })
    setLoading(false)
    setSended(true)
  }

  return (
    <Container>
      <Title>{sended ? 'Senha Alterada' : 'Alterar Senha'}</Title>
      {!sended && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSubmit)}>
            <Input
              name="password"
              label="Senha"
              type="password"
              placeholder="Digite a sua senha"
            />
            <Input
              name="confirmPassword"
              label="Confirme a Senha"
              type="password"
              placeholder="Digite a sua senha"
            />
            <Button type="submit" loading={loading}>
              Alterar Senha
            </Button>
          </form>
        </FormProvider>
      )}
      {sended && (
        <>
          <span className="success">Senha atualizada com sucesso!</span>
          <Link to="/auth/login">
            Agora você pode <u>entrar</u> novamente!
          </Link>
        </>
      )}
    </Container>
  )
}
