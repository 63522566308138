import styled from 'styled-components'

export const Container = styled.section`
  flex: 1;
  padding: 1.6rem 3.2rem 0.8rem;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 3.2rem;

  @media (max-width: 1400px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`

export const NumberBox = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundBlack};
  border-radius: 3.2rem;
  padding: 3.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;

  h2 {
    font-size: 3.2rem;
    text-align: center;
  }

  p {
    font-size: 12.8rem;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.green};
    text-align: center;

    &.date {
      font-size: 4rem;
    }
  }
`
