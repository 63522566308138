import styled from 'styled-components'

export const Container = styled.section`
  position: relative;

  > button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 12.4rem;
    padding: 0 1rem 0 1.6rem;
    height: 3.2rem;
    border: none;
    background-color: ${(props) => props.theme.colors.backgroundDark};
    font-weight: 500;
    font-size: 1.4rem;
    border-radius: 0.8rem;
    cursor: pointer;
    box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.1);
  }
`

export const WalletList = styled.ul`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: calc(100% + 0.2rem);
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.1);
  max-height: 0;
  list-style: none;
  overflow: auto;
  background-color: ${(props) => props.theme.colors.backgroundDark};
  border-radius: 0.8rem;
  z-index: 999;

  &.active {
    max-height: 30rem;
  }
`

export const WalletItem = styled.li`
  button {
    padding: 0.8rem 1.6rem;
    min-width: 12.4rem;
    border: none;
    background: rgba(255, 255, 255, 0.02);
    display: flex;
    font-size: 1.4rem;

    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
      cursor: pointer;
    }
  }
`
