import { Fragment, useMemo } from 'react'

import { ChangeWallet } from '../../../../../components/Inputs/ChangeWallet'
import { useAuth } from '../../../../../hooks/AuthContextProvider'
import { Container } from './styles'

interface IHeaderPageProps {
  title?: string
}

export const HeaderPage: React.FC<IHeaderPageProps> = ({ title }) => {
  const { user } = useAuth()
  const message = useMemo(() => {
    const date = new Date()
    const hours = date.getHours()

    if (hours >= 7 && hours < 12) {
      return ', tenha um bom dia! <span>☀️</span>'
    }
    if (hours >= 12 && hours < 18) {
      return ', tenha uma boa tarde! <span>☀️</span>'
    }
    if (hours >= 18 && hours <= 23) {
      return ', tenha uma boa noite! <span>🌔</span>'
    }
    if (hours >= 0 && hours < 7) {
      return ', tenha uma boa madrugada! <span>🌔</span>'
    }
    return '.'
  }, [])

  return (
    <Container>
      <h2>
        {title ?? (
          <>
            Olá <span className="username">{user?.firstName}</span>
            <span
              className="message"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          </>
        )}
      </h2>

      <ChangeWallet />
    </Container>
  )
}
