import { ThemeProvider } from 'styled-components'

import { GlobalStyle } from '../styles/global'
import { myTheme } from '../styles/theme'
import { AuthContextProvider } from './AuthContextProvider'
import { ChangesContextProvider } from './ChangesContextProvider'
import { ModalContextProvider } from './ModalContextProvider'
import { PlanContextProvider } from './PlanContextProvider'
import { ToastContextProvider } from './ToastContextProvider'
import { WalletContextProvider } from './WalletContextProvider'

interface IHookProps {
  children: React.ReactNode
}

export const Hooks: React.FC<IHookProps> = ({ children }) => {
  return (
    <ThemeProvider theme={myTheme}>
      <GlobalStyle />

      <ToastContextProvider>
        <AuthContextProvider>
          <PlanContextProvider>
            <ChangesContextProvider>
              <WalletContextProvider>
                <ModalContextProvider>{children}</ModalContextProvider>
              </WalletContextProvider>
            </ChangesContextProvider>
          </PlanContextProvider>
        </AuthContextProvider>
      </ToastContextProvider>
    </ThemeProvider>
  )
}
