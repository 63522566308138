import styled from 'styled-components'

interface IContainerProps {
  align: 'left' | 'center' | 'right'
}
interface IPreviewIconButtonProps {
  color?: string
}
export const Container = styled.section.withConfig({
  shouldForwardProp: (prop) => prop !== 'align',
})<IContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  align-items: ${(props) =>
    props.align.replace('left', 'flex-start').replace('right', 'flex-end')};

  .error {
    text-align: center;
    display: block;
    margin-top: 0.4rem;
    color: ${(props) => props.theme.colors.red};
  }
`

export const PreviewIconButton = styled.button.withConfig({
  shouldForwardProp: (prop) => prop !== 'color',
})<IPreviewIconButtonProps>`
  width: 4.8rem;
  height: 4.8rem;
  background-color: ${(props) => props.theme.colors.backgroundBlack};
  border: none;
  border-radius: 0.8rem;
  color: ${(props) => props.color ?? props.theme.colors.textLight};

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const IconsList = styled.section`
  position: absolute;
  top: calc(100% + 0.4rem);
  right: 0;
  max-height: 30rem;
  z-index: 9;
  overflow: hidden auto;
  background-color: ${(props) => props.theme.colors.backgroundDark};
  padding: 0.8rem;
  border-radius: 0.8rem;
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.2);

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 0.8rem;
`

export const SelectIconButton = styled.button`
  width: 4.8rem;
  height: 4.8rem;
  background-color: ${(props) => props.theme.colors.backgroundDark};
  border: none;
  border-radius: 0.8rem;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.backgroundDarker};
  }
`
