import { createContext, useContext, useState } from 'react'

import { type IModal } from '../../@types/IModal'
import { type Optional } from '../../@types/Optional'
import { ModalList } from './ModalList'

interface IModalContextProviderProps {
  children: React.ReactNode
}

interface ICreateContext {
  modals: IModal[]
  addModal: (modal: Optional<IModal, 'id'>) => string
  removeModal: (modalId: string) => void
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const ModalContext = createContext({} as ICreateContext)

export const ModalContextProvider: React.FC<IModalContextProviderProps> = ({
  children,
}) => {
  const [modals, setModals] = useState<IModal[]>([])

  function addModal({ id, content }: Optional<IModal, 'id'>) {
    if (!id) {
      id = (Math.random() + 1).toString(36).substring(7)
    }

    const modal: IModal = { id, content }

    setModals((state) => {
      return [...state, modal]
    })

    return id
  }

  function removeModal(modalId: string) {
    setModals((state) => {
      return [...state.filter((item) => item.id !== modalId)]
    })
  }

  return (
    <ModalContext.Provider value={{ modals, addModal, removeModal }}>
      {children}
      <ModalList modals={modals} removeModal={removeModal} />
    </ModalContext.Provider>
  )
}

export const useModal = () => {
  const context = useContext(ModalContext)

  if (!context) {
    throw new Error('Theres a problem with ModalContext')
  }

  return context
}
