import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  background-color: ${(props) => props.theme.colors.backgroundBlack};
  border-radius: 1.6rem;

  h3 {
    font-size: 1.8rem;
    line-height: 1.8rem;
  }

  canvas {
    max-height: 20rem !important;
  }

  .expenses-by-period {
    width: 100%;
    background-color: ${(props) => props.theme.colors.backgroundBlack};
    padding: 1.6rem;
    border-radius: 1.6rem;
  }

  .loading-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10rem;
  }
`
