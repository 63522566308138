import styled from 'styled-components'

export const Container = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  img {
    display: block;
    max-width: 25rem;
    margin-bottom: 3.2rem;
  }

  footer {
    text-align: center;
    display: flex;
    gap: 0.4rem;

    .terms {
      display: flex;
      gap: 1.6rem;
      color: ${(props) => props.theme.colors.primary};
      text-decoration: underline;
      position: fixed;
      bottom: 1.6rem;
      right: 1.6rem;
    }
  }
`

export const Box = styled.section`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 40rem;
  background-color: ${(props) => props.theme.colors.backgroundStart};
  padding: 3.2rem;
  box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.3);
  border-radius: 1.6rem;
  margin-bottom: 6.4rem;
`
