import { NotificationList } from './NotificationList'
import { Container } from './styles'
import { User } from './User'

export const HeaderUser: React.FC = () => {
  return (
    <Container>
      <div className="col">
        <NotificationList />
        {/* <Search /> */}
      </div>
      <User />
    </Container>
  )
}
