import styled, { css } from 'styled-components'

interface IContainerProps {
  model: 'normal' | 'small'
}

export const Container = styled.button.withConfig({
  shouldForwardProp: (prop) => prop !== 'model',
})<IContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.6rem;
  width: 100%;
  border: none;
  border-radius: 0.8rem;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.textDark};
  font-weight: 700;
  font-size: 1.8rem;
  transition: 0.2s;
  cursor: pointer;

  ${(props) =>
    props.model === 'small' &&
    css`
      width: fit-content;
      height: fit-content;
      padding: 0.8rem 1.6rem;
      font-size: 1.6rem;
    `}

  &:hover {
    background-color: ${(props) => props.theme.colors.primaryDark};
  }

  &:disabled {
    background-color: ${(props) => props.theme.colors.backgroundLight};
  }
`
