import styled from 'styled-components'

export const Container = styled.header`
  grid-area: header;
  background-color: ${({ theme }) => theme.colors.backgroundDarker};
  display: grid;
  grid-template-columns: 1fr 50rem;
  border-radius: 3.2rem 0 0 0;
  padding: 2.4rem 3.2rem 0;
  grid-template-areas: 'header-title header-user';
  gap: 3.2rem;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr 40rem;
  }

  @media (max-width: 1000px) {
    display: grid;
    grid-template-areas: 'header-user' 'header-title';
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0;
    padding-top: 2.4rem;
  }

  @media (max-width: 730px) {
    border-radius: 0;
  }

  @media (max-width: 460px) {
    padding: 2.4rem 1.6rem 0;
  }
`
